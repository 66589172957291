import React, { useState } from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { WebJson } from '../types/WebJson';
import { UserModel} from '../types/UserModel';
import { MessageTypeEnum } from '../enums/MessageTypeEnum';

const styles = {
    responseContainer: css({
        padding: '1px',
        marginBottom: '25px',
    }),
    successMessage: css({
        border: '1px solid green',
        background: 'lightgreen',
    }),
    errorMessage: css({
        border: '1px solid red',
        background: 'lightsalmon',
    }),
    list: css({
        listStyleType: 'none',
        padding: 0,
        fontSize: '12px',
    }),
};

interface FormResponseType {
    handleFormResponse: (formResponse: any) => void;
    formResult: JSX.Element | undefined;
}

export const useFormResponse = (): FormResponseType => {
    const [formResult, setFormResult] = useState<React.ReactElement>();

    const handleFormResponse = (formResponse: WebJson<UserModel>) => {
        const errorList = [];
        const unexpectedErrorMsg = 'An unexpected error has occurred. Please try again.';
        const errorMsgs = formResponse?.messages?.filter(m =>
            m.messageType === MessageTypeEnum.Error ||
            m.messageType === MessageTypeEnum.Warning);

        if (formResponse.isSuccess) {
            setFormResult(
                <div css={[styles.responseContainer, styles.successMessage]}>
                    <ul css={styles.list}>
                        <li>Success</li>
                    </ul>
                </div>
            );
        } else if (errorMsgs && errorMsgs.length > 0) {
            Object.entries(errorMsgs)
                .forEach(([_key, value]) => {
                    errorList.push(value.message);
                });
        }
        else {
            errorList.push(unexpectedErrorMsg);
        }

        if (errorList.length > 0) {
            setFormResult(
                <div css={[styles.responseContainer, styles.errorMessage]}>
                    <ul css={styles.list}>
                        {errorList.map((error, index) => <li key={index}>{error}</li>)}
                    </ul>
                </div>
            );
        }
    };

    return {
        handleFormResponse,
        formResult,
    };
};
