import { MessageTypeEnum } from '../../enums/MessageTypeEnum';
import React, {FunctionComponent} from 'react';
import Alert from '@mui/material/Alert';


interface Props {
    severity : MessageTypeEnum,
    message : String
}

export const FormAlert: FunctionComponent<Props> = ({severity, message}) => {
    
    if(severity === MessageTypeEnum.Error){
        return (
            <>
                <Alert severity="error">
                    {message}
                </Alert>
            </>
        )
    }else if(severity === MessageTypeEnum.Success){
        return (
            <>
                <Alert severity="success">
                    {message}
                </Alert>
            </>
        )
    }
    else if(severity === MessageTypeEnum.Warning){
        return (
            <>
                <Alert severity="warning">
                    {message}
                </Alert>
            </>
        )
    }
    else if(severity === MessageTypeEnum.Info){
        return (
            <>
                <Alert severity="info">
                    {message}
                </Alert>
            </>
        )
    }
    else{
        <>
        </>
    }
    return (<></>);
    
}