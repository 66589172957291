import React,{FunctionComponent, useEffect} from 'react';
import { FilterSelect } from './FilterSelect';
import { SelectedFilter } from './SelectedFilter';
import { JobClient } from '../../apiClients/jobClient';
import { useLocation } from "react-router-dom";
import queryString from 'query-string';


interface Props {

}

const jobfilterDict : any = {
    101: "Job Type",
    103: "Category",
    104: "Compensation",
    105: "Experience",
    107: "Education",
    // 108: "Currency",
    // 109 : "Country"
}


export const JobFilterBar : FunctionComponent<Props> = () => {
    const [allFilters, setAllFilters] = React.useState<any>();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const [selected, setSelected] = React.useState([]);
     useEffect(()=>{
        async function fetchAllFilters(){
            const result = await JobClient.getFiltersAsync();
            if(result?.isSuccess)
                setAllFilters(result?.data);            
        }
        fetchAllFilters();
    },[]);

    useEffect(()=>{
        const filters = queryParams?.filters;
        if(Boolean(filters)){
            // @ts-ignore
            const arr = JSON.parse(filters);
            setSelected(arr);
        }
    },[queryParams])

    return(
        <>
            <div style={{minHeight:"10vh", margin:"0rem 7vw", borderRadius:"10px"}}>
                <div style={{padding:"0 2rem", display:"flex"}}>
                    {
                        selected?.map((each:any)=>{
                            return (
                                <SelectedFilter label={each?.label} value={each?.value} />
                            )
                        })
                    }
                </div>
                <div style={{marginTop:"1rem", border:"1px solid #f3f3f3", display:"flex", overflowX:"scroll", justifyContent:"space-evenly"}}>
                    {
                        allFilters?.map((each:any)=>{
                            if(each.type in jobfilterDict){
                                return (
                                    <FilterSelect options={each.options} inputLabel={jobfilterDict[each.type]} />
                                )
                            }
                            return null
                        })
                    }
                </div>
            </div>
        </>
    )
}