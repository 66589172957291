import React, {
    FunctionComponent, useEffect, useState,
} from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { FormContainer } from '../../ui/form/FormContainer';
import { Button }  from "@mui/material";
import { Textbox } from '../../ui/form/Textbox';
import { Dropdown, DropdownOptions } from '../../ui/form/Dropdown';
import { useFormResponse } from '../../hooks/useFormResponse';
import { JobStatusEnum } from '../../enums/JobStatusEnum';
import { useMutation, useQuery } from 'react-query';
import { JobClient } from '../../apiClients/jobClient';
import JobFilterEnum from '../../enums/JobFilterEnum';
import { CompanyClient } from '../../apiClients/companyClient';
import { CreateJobRequest } from '../../types/requests/CreateJobRequest';
import { useHistory } from 'react-router';
import { TextEditor } from '../../ui/form/TextEditor';
import { SecondaryButton } from '../../ui/SecondaryButton';
import { PrimaryButton } from '../../ui/PrimaryButton';
import { FormAlert } from '../../ui/form/FormAlert';
import { MessageTypeEnum } from '../../enums/MessageTypeEnum';

const styles = {
    btnCancel: css({
        marginLeft: '20px',
    }),
    fields: css({
        display: 'flex',
        flexDirection: 'row',
        margin: '0 - .5em 1em',
    }),
};

interface Props {
    formData?:any
}

interface AllJobFilter{
    employmentType:number,
    educationLevel:number,
    country:number,
    city:number,
    skills:number[],
    compensationCurrency:number,
    experience:number,
    compensationType:number,
    category:number
}

export const JobForm: FunctionComponent<Props> = ({formData=null }) => {  
  
    const history = useHistory();
    const [jobTitle, setJobTitle] = useState<string>("");
    const [jobDescription, setJobDescription] =useState<string>("");
    const { formResult } = useFormResponse();
    const [jf, setJf] = useState<any>([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [companyID, setCompanyID] = useState<number>();
    const [companyOptions, setCompanyOptions] =useState<DropdownOptions[]>();
    const [positionAvailable,setPositionAvailable] = useState(1);
    const [compensation,setCompensation] = useState(0);
    const {isLoading, isSuccess, data} = useQuery('UserCompany',()=>CompanyClient.getAsync());
    const [severity, setSeverity] = useState<MessageTypeEnum>(MessageTypeEnum.Unknown);
    const [alertMessage, setAlertMessage] = useState<string>("");
    

    const initialState = {
        employmentType:0,
        educationLevel:0,
        country:0,
        city:0,
        skills:[],
        compensationCurrency:0,
        experience:0,
        compensationType:0,
        category:0
    };
    const [allJobFilters, setAllJobFilters] = useState<AllJobFilter>(initialState);

    const setEditData = (jobData:any)=>{
        setJobTitle(jobData?.title);
        setJobDescription(jobData?.description);
        setPositionAvailable(Number(jobData?.positionsAvailable));
        setCompensation(Number(jobData?.compensation));
        const stDate = new Date(jobData?.applicationStartDate).toISOString().split('T')[0];
        const edDate = new Date(jobData?.applicationEndDate).toISOString().split('T')[0];
        setStartDate(stDate);
        setEndDate(edDate);
        setCompanyID(jobData?.company?.companyID);

        const jobFiltersData = jobData?.jobFilterValues;
        const skills = jobFiltersData?.filter((x:any) => x.filterTypeID === JobFilterEnum.Skills).map((each:any)=> each.filterID);

        const editFilterState  ={
            employmentType: jobFiltersData?.find((x:any) => x.filterTypeID === JobFilterEnum.EmploymentType)?.filterID,
            educationLevel: jobFiltersData?.find((x:any) => x.filterTypeID === JobFilterEnum.EducationLevel)?.filterID,
            country: jobFiltersData?.find((x:any) => x.filterTypeID === JobFilterEnum.LocationCountry)?.filterID,
            city: jobFiltersData?.find((x:any) => x.filterTypeID === JobFilterEnum.Location)?.filterID,
            compensationCurrency: jobFiltersData?.find((x:any) => x.filterTypeID === JobFilterEnum.CompensationCurrency)?.filterID,
            experience: jobFiltersData?.find((x:any) => x.filterTypeID === JobFilterEnum.ExperienceLevel)?.filterID,
            compensationType: jobFiltersData?.find((x:any) => x.filterTypeID === JobFilterEnum.CompensationType)?.filterID,
            category: jobFiltersData?.find((x:any) => x.filterTypeID === JobFilterEnum.Category)?.filterID,
            skills: skills,
        }
        return editFilterState;
    }

    useEffect(() => {
         if(isSuccess && !isLoading){
            const options:DropdownOptions[] = [];
            data?.data?.map(each=>{
                const opt = {
                    "value":each.companyID,
                    "label":each.name
                };
                options.push(opt);
            });
            setCompanyOptions(options);
        }
        if(formData){
            const allJobFilters = setEditData(formData);
            setAllJobFilters(allJobFilters); 
            setJobDescription(formData?.description)
        }
       
    }, [isSuccess,isLoading,formData,setAllJobFilters,formData]);

    useEffect(()=>{
       async function  fetchFilters()
       {
        const result = await JobClient.getFiltersAsync();
        if(result.isSuccess)
            setJf(result?.data);
       }
       fetchFilters();
    },[])
 
    const jobMutation = useMutation((request:CreateJobRequest) =>JobClient.createJob(request));
    const jobUpdateMutation = useMutation((request: CreateJobRequest)=>JobClient.updateJob(formData.jobID, request));

    const createRequest = (status:JobStatusEnum)=>{
        let jobFilterIDs:number[] = [];
        Object.keys(allJobFilters).forEach((each:string)=>{
            if (each == 'skills'){
                jobFilterIDs = jobFilterIDs.concat(allJobFilters[each]);
            }
            else{
                const allFilters : { [key: string]: number|[] } = JSON.parse(JSON.stringify(allJobFilters));
                if( allFilters[each] != 0){
                    jobFilterIDs.push(allFilters[each] as number)
                }
            }
        });
        const request= {
            "title": jobTitle,
            "description": jobDescription,
            "positionsAvailable": positionAvailable,
            "compensation": compensation,
            "applicationStartDate": startDate,
            "applicationEndDate": endDate,
            "rating": 0,
            "companyID": companyID,
            "jobFilterIDs": jobFilterIDs,
            "jobStatus": status
          }
        return request;
    }
    const isValid = (data:CreateJobRequest) : boolean=>{
        let isValidated = true;
        if(!Boolean(data.title)){
            setSeverity(MessageTypeEnum.Warning);
            setAlertMessage("Job title is required");
            isValidated = false;
        }else if(!Boolean(jobDescription)){
            setSeverity(MessageTypeEnum.Warning);
            setAlertMessage("Job description is required");
            isValidated = false;
        }else if(!Boolean(data.positionsAvailable)){
            setSeverity(MessageTypeEnum.Warning);
            setAlertMessage("Position available is required");
            isValidated = false;
        }else if(!Boolean(data.compensation)){
            setSeverity(MessageTypeEnum.Warning);
            setAlertMessage("compensation is required");
            isValidated = false;
        }else if(!Boolean(companyID)){
            setSeverity(MessageTypeEnum.Warning);
            setAlertMessage("Please select a company");
            isValidated = false;
        }else if(!Boolean(data.applicationStartDate)){
            setSeverity(MessageTypeEnum.Warning);
            setAlertMessage("Start date is required");
            isValidated = false;
        }else if(!Boolean(data.applicationEndDate)){
            setSeverity(MessageTypeEnum.Warning);
            setAlertMessage("End date is required");
            isValidated = false;
        }
        return isValidated;
    }
    function scrollToTop(){
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

    }

    async function handleUpdate(e:React.SyntheticEvent){
        e.preventDefault();
        //switch with jobstatus if 
        const request:CreateJobRequest = createRequest(JobStatusEnum.Active);
        const isValidated = isValid(request);
        if(!isValidated){
            scrollToTop();
            return;
        }
        let response = await jobUpdateMutation.mutateAsync(request);
        if (response.isSuccess){
            history.goBack();
            alert("Sucessfully updated your job.");
        }
        else if(response?.status === 401){
            alert("Session Expired, Please Login again");
            history.push("/login");
        }
        else{
            scrollToTop();
            setSeverity(MessageTypeEnum.Error);
            setAlertMessage("Something went wrong! Cannot update your progress!")
        }
    }
    
    async function handleSumbit(e: React.SyntheticEvent) {
        e.preventDefault();
        const request:CreateJobRequest = createRequest(JobStatusEnum.Active);
        const isValidated = isValid(request);
        if(!isValidated){
            scrollToTop();
            return;
        }

        let response = await jobMutation.mutateAsync(request);
        if(response?.status === 401){
            alert("Session Expired, Please Login again");
            history.push("/login");
        }
        if (response.isSuccess){
            history.goBack();
            alert("Sucessfully added your job.");
        }
        else{
            scrollToTop();
            setSeverity(MessageTypeEnum.Error);
            setAlertMessage("Unable to add the job!")
        }

    };
    async function handleDraft (e: React.SyntheticEvent){
        e.preventDefault();
        const request:CreateJobRequest = createRequest(JobStatusEnum.Drafted)
        const isValidated = isValid(request);
        if(!isValidated){
            scrollToTop();
            return;
        }
        let response = await jobMutation.mutateAsync(request);
        if(response?.status === 401){
            alert("Session Expired, Please Login again");
            history.push("/login");
        }
        if (response?.isSuccess){
            history.goBack();
            alert("Sucessfully added to the draft.");
        }
        else{
            scrollToTop();
            setSeverity(MessageTypeEnum.Error);
            setAlertMessage("Something went wrong! Cannot save to draft!")
        }
    }
   
    const handleOnChange=(options:DropdownOptions[]|DropdownOptions)=>{
        const skillsList = (options as DropdownOptions[]).map(opt => opt.value);
        setAllJobFilters((prevState) =>{ return {...prevState,"skills":skillsList}});
    }
    let button  = (
        <> 
            <Button 
                type = "submit"
                variant="contained" 
                style={{backgroundColor:"#7962D2", color:"white", fontSize:"0.9rem", textAlign:"center", margin:"0 2rem"}} 
            >
                Post Job
            </Button>
            <PrimaryButton                
                buttonText ="Draft"
                onClick={(e:any)=>handleDraft(e)}
            /> 
        </>);
    if(formData != null){
        button = (<>
            <PrimaryButton
                buttonText = "Update"
                onClick={(e:any)=>handleUpdate(e)}
            /> 
        </>)
    }

    const handleEditorChange = (content:string) => {
        setJobDescription(content);
    }
                   
    return (
        <div style={{width:"100%"}}>
                <FormContainer handleSubmit={handleSumbit} formResult={formResult}>
                    <FormAlert 
                        severity={severity}
                        message = {alertMessage}
                    />
                    <div css={styles.fields}>
                    <Textbox
                        label="Job Title"
                        type="text"
                        id="jobTitle"
                        value = {jobTitle}
                        onChange={(e:React.SyntheticEvent<HTMLInputElement, Event>)=>{setJobTitle((e.target as HTMLInputElement).value)}}
                        required
                    />
                     <Textbox
                        label="Positions Available"
                        type="number"
                        id="positionsAvailable"
                        required
                        value = {positionAvailable.toString()}
                        onChange={(e:React.SyntheticEvent<HTMLInputElement, Event>)=>{setPositionAvailable(Number((e.target as HTMLInputElement).value))}}
                    />
                    </div>
                    <TextEditor label="Job Description" id="Job_Description" onChange={handleEditorChange} value={jobDescription}/>
                    
                    <div css={styles.fields}>
                        <Dropdown 
                            label="Category/Industry" 
                            id="Category/Industry" 
                            options={jf?.find((x:any)=>(x.type)==JobFilterEnum.Category)?.options as DropdownOptions[]}
                            onChange={
                                (options:DropdownOptions|DropdownOptions[])=>{
                                    setAllJobFilters(prevState =>{return {...prevState,"category":(options as DropdownOptions).value}});
                                }
                            } 
                            value={[allJobFilters?.category]}
                        />
                        <Textbox
                            label="Compensation"
                            type="number"
                            id="compensation"
                            value = {compensation.toString()}
                            required
                            onChange={(e:React.SyntheticEvent<HTMLInputElement, Event>)=>setCompensation(Number((e.target as HTMLInputElement).value))}
                        />
                        </div>
                        <div css={styles.fields}>

                        <Textbox
                            label="Application Start Date"
                            type="date"
                            id="applicationStartDate"
                            value = {startDate}
                            onChange={(e:React.SyntheticEvent<HTMLInputElement, Event>)=>{setStartDate(new Date((e.target as HTMLInputElement).value).toISOString().split('T')[0])}}
                            required
                        />
                        <Textbox
                            label="Application End Date"
                            type="date"
                            id="applicationEndDate"
                            value={endDate}
                            onChange={(e:React.SyntheticEvent<HTMLInputElement, Event>)=>setEndDate(new Date((e.target as HTMLInputElement).value).toISOString().split('T')[0])}
                            required
                        />
                    </div>
                    <div css={styles.fields}>

                    <Dropdown 
                        label="Employment Type" 
                        id="EmploymentType" 
                        value = {[allJobFilters?.employmentType]}
                        options={jf?.find((x:any)=>(x.type)==JobFilterEnum.EmploymentType)?.options as DropdownOptions[]}
                        onChange={
                            (options:DropdownOptions|DropdownOptions[])=>{setAllJobFilters(prevState =>{return {...prevState,"employmentType":(options as DropdownOptions).value}})}}
                    />
                    <Dropdown 
                        multiSelectable={true} 
                        label="Skills" 
                        id="Skills" 
                        options={jf?.find((x:any)=>(x.type)==JobFilterEnum.Skills)?.options as DropdownOptions[]} 
                        onChange={handleOnChange} 
                        value={allJobFilters?.skills}
                    />
                    </div>
                    <div css={styles.fields}>

                    <Dropdown 
                        label="Education Level" 
                        id="EducationLevel" 
                        options={jf?.find((x:any)=>(x.type)==JobFilterEnum.EducationLevel)?.options as DropdownOptions[]} 
                        onChange={(options:DropdownOptions|DropdownOptions[])=>{setAllJobFilters(prevState =>{return {...prevState,"educationLevel":(options as DropdownOptions).value}})}} 
                        value={[allJobFilters?.educationLevel]}
                    />
                    <Dropdown 
                        label="Locaton City" 
                        id="LocationCity" 
                        options={jf?.find((x:any)=>(x.type)==JobFilterEnum.Location)?.options as DropdownOptions[]} 
                        onChange={(options:DropdownOptions|DropdownOptions[])=>{setAllJobFilters(prevState =>{return {...prevState,"city":(options as DropdownOptions).value}})}} 
                        value={[allJobFilters?.city]}
                    />
                    </div>
                  <div css={styles.fields}>

                    <Dropdown 
                        label="Location Country" 
                        id="LocationCountry" 
                        options={jf?.find((x:any)=>(x.type)==JobFilterEnum.LocationCountry)?.options as DropdownOptions[]} 
                        onChange={(options:DropdownOptions|DropdownOptions[])=>{setAllJobFilters(prevState =>{return {...prevState,"country":(options as DropdownOptions).value}})}} 
                        value={[allJobFilters?.country]}
                    />
                    <Dropdown 
                        label="Compensation Currency" 
                        id="CompensatonCurrency" 
                        options={jf?.find((x:any)=>(x.type)==JobFilterEnum.CompensationCurrency)?.options as DropdownOptions[]} 
                        onChange={(options:DropdownOptions|DropdownOptions[])=>{setAllJobFilters(prevState =>{return {...prevState,"compensationCurrency":(options as DropdownOptions).value}})}} 
                        value={[allJobFilters?.compensationCurrency]}
                    />
                    </div>
                  <div css={styles.fields}>

                    <Dropdown 
                        label="Experience Level" 
                        id="ExperienceLevel" 
                        options={jf?.find((x:any)=>(x.type)==JobFilterEnum.ExperienceLevel)?.options as DropdownOptions[]} 
                        onChange={(options:DropdownOptions|DropdownOptions[])=>{setAllJobFilters(prevState =>{return {...prevState,"experience":(options as DropdownOptions).value}})}} 
                        value={[allJobFilters?.experience]}
                    />
                    <Dropdown 
                        label="Compensation Type" 
                        id="CompensationType" 
                        options={jf?.find((x:any)=>(x.type)==JobFilterEnum.CompensationType)?.options as DropdownOptions[]} 
                        onChange={(options:DropdownOptions|DropdownOptions[])=>{setAllJobFilters(prevState =>{return {...prevState,"compensationType":(options as DropdownOptions).value}})}} 
                        value={[allJobFilters?.compensationType]}
                    />
                    </div>
                    <Dropdown 
                        label="Company" 
                        id="Company" 
                        options={companyOptions as DropdownOptions[]} 
                        onChange={(options:DropdownOptions|DropdownOptions[])=>{setCompanyID((options as DropdownOptions).value)}} 
                        value={[companyID]}
                    />
                    <div style={{margin:"3rem 0"}}>
                        {button}
                        <SecondaryButton
                            buttonText="Cancel"
                            onClick={()=>{history.goBack()}}
                        />
                    </div>
                </FormContainer>
        </div>
    );
};
