import React, { FunctionComponent, useEffect } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { JobClient } from "../../apiClients/jobClient";
import { SideBarMenu } from "./SideBarMenu";
import { useHistory } from "react-router-dom";
import { DashboardRequestModel } from "../../types/DashboardRequestModel";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import { useParams } from 'react-router-dom';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Applications Received Data',
    },
  },
};
enum DashFilterType{
    time = 2,
    date = 1,
    unknown = 0
}
const ChartFilterOptions  = [
    {value:"24", label:"Last 24 hours", type: DashFilterType.time},
    {value:"12", label:"Last 12 hours", type: DashFilterType.time},
    {value:"7", label:"Last 7 days", type: DashFilterType.date},
    {value:"15", label:"Last 15 days", type:DashFilterType.date}
];
export const ResponsiveDashBoard: FunctionComponent = () => {
    const [dashboardInfo, setDashboardInfo] = React.useState<any>();
    const history = useHistory();
    const {filterId} :{filterId :any} = useParams();
    const [duration, setDuration] = React.useState<any>({value:"7", label:"Last 7 days", type: DashFilterType.date});
    
    console.log("###", ChartFilterOptions);
    const handleChange = (event: SelectChangeEvent) => {
        const filter = ChartFilterOptions.find((e:any)=>e.value == event.target.value);
        setDuration(filter);
        const val = event.target.value as string;
        history.push("/dashboard/"+val);
    };
    useEffect(()=>{
        if(Boolean(filterId))
        {
            const filter = ChartFilterOptions.find((e:any)=>e.value == filterId);
            setDuration(filter);
        }
    },[filterId])
    useEffect(()=>{
        async function fetchDashboardInfor(){
            const filter = ChartFilterOptions.find((e:any)=>e.value == filterId);
            let req :DashboardRequestModel = {
                InfoFilterType: Number(filter?.type),
                NDays: Number(filter?.value),
                NHours: Number(filter?.value) 
            };
            const result = await JobClient.getDashboardInfo(req);
            if(result?.isSuccess)
                setDashboardInfo(result?.data);
            if(result?.status === 401){
                history.push("/login");
            }
        }
        fetchDashboardInfor();
    },[filterId])

    return (
        <>
            <Box style={{margin:"0", padding:"0"}} sx={{ flexGrow: 1, display: { xs: 'none', md: 'inline' } }}>
                <div style={{minHeight:"100vh", display:"flex", justifyContent:"space-between"}}>
                    <SideBarMenu />
                    <div style={{minWidth:"70vw", marginTop:"2rem", marginBottom:"2rem", textAlign:"left", paddingLeft:"0rem", marginRight:"2rem" ,border:"1px solid #f1f1f1"}}>
                        <Grid container spacing={3}>
                            {
                                dashboardInfo?.map((each:any)=>{
                                    if(each?.dataType == "chart")
                                    {
                                        return null;
                                    }
                                    return (
                                        <Grid item xs={6} key={each?.id}>
                                            <div style={{border:"1px solid #f1f1f1",borderRadius:"7px", padding:"1rem 2rem", background:"#ECECEC", display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                                                <h2 style={{color:"#7962D2", fontWeight:"bolder"}}>{each?.title}</h2>
                                                <h2 style={{color:"#7962D2", fontWeight:"bolder"}}> {each?.data}</h2>  
                                            </div>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                        <div style={{textAlign:"right", marginRight:"2.5rem", marginTop:"2rem"}}>
                            <FormControl style={{width:"300px"}}>
                                <InputLabel id="demo-simple-select-label">Duration</InputLabel>
                                <Select
                                    style={{background:"#d3d3d3", color:"black"}}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={duration.value}
                                    label="Jobs"
                                    onChange={handleChange}
                                >
                                    {
                                        ChartFilterOptions?.map((each:any)=>{
                                            return(
                                                <MenuItem key={each.value} value={each.value}>{each.label}</MenuItem>
                                            )
                                        })
                                    }
                                
                                </Select>
                            </FormControl>
                        </div>
                        {
                            <div style={{background:"#ECECEC", marginTop:"2rem", padding:"1rem 0" ,display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"row"}}>
                                 {
                                dashboardInfo?.map((each:any)=>{
                                    if(each?.dataType == "chart")
                                    {         
                                        const barChartData = {
                                            labels: each.data.label,
                                            scales:{
                                                xAxes: [{
                                                    ticks: {
                                                        display: false 
                                                    }                                            
                                                }]
                                            },
                                            datasets: [{
                                                label: 'Applications',
                                                data: each.data.data,
                                                backgroundColor: [
                                                'rgba(255, 99, 132, 0.2)',
                                                'rgba(255, 159, 64, 0.2)',
                                                'rgba(255, 205, 86, 0.2)',
                                                'rgba(75, 192, 192, 0.2)',
                                                'rgba(54, 162, 235, 0.2)',
                                                'rgba(153, 102, 255, 0.2)',
                                                'rgba(201, 203, 207, 0.2)'
                                                ],
                                                borderColor: [
                                                'rgb(255, 99, 132)',
                                                'rgb(255, 159, 64)',
                                                'rgb(255, 205, 86)',
                                                'rgb(75, 192, 192)',
                                                'rgb(54, 162, 235)',
                                                'rgb(153, 102, 255)',
                                                'rgb(201, 203, 207)'
                                                ],
                                                border: 1
                                            }]
                                            };
                                        return (<Bar options={options} data={barChartData} />);
                                    }
                                    return null;
                                    
                                })
                            }
                            </div>
                        }
                        <p style={{textAlign:"center", color:"gray"}}>Job Applications Received VS Time</p>
                    </div>
                </div>
            </Box>

             <Box sx={{ flexGrow: 1, display: { xs: 'inline', md: 'none' } }} >
                <SideBarMenu/>
                <div style={{padding:"0 1rem", marginBottom:"3rem", marginTop:"0rem", border:"1px solid #f1f1f1"}}>
                        <Grid container spacing={3}>
                            {
                                dashboardInfo?.map((each:any)=>{
                                    if(each?.dataType == "chart")
                                    {
                                        return null;
                                    }
                                    return (
                                        <Grid item xs={12} key={each?.id}>
                                            <div style={{border:"1px solid #f1f1f1",borderRadius:"7px", padding:"1rem 2rem", background:"#ECECEC",display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                                                <h3 style={{color:"#7962D2", fontWeight:"bolder"}}>{each?.title}</h3>  
                                                <h3 style={{color:"#7962D2", fontWeight:"bolder"}}> {each?.data}</h3>
                                            </div>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                        {
                            <div style={{background:"#ECECEC", marginTop:"2rem", padding:"1rem 0" ,display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"row"}}>
                                 {
                                dashboardInfo?.map((each:any)=>{
                                    if(each?.dataType == "chart")
                                    {                                        
                                        const barChartData = {
                                            labels: each.data.label,
                                            datasets: [{
                                                label: 'My First Dataset',
                                                data: each.data.data,
                                                backgroundColor: [
                                                'rgba(255, 99, 132, 0.2)',
                                                'rgba(255, 159, 64, 0.2)',
                                                'rgba(255, 205, 86, 0.2)',
                                                'rgba(75, 192, 192, 0.2)',
                                                'rgba(54, 162, 235, 0.2)',
                                                'rgba(153, 102, 255, 0.2)',
                                                'rgba(201, 203, 207, 0.2)'
                                                ],
                                                borderColor: [
                                                'rgb(255, 99, 132)',
                                                'rgb(255, 159, 64)',
                                                'rgb(255, 205, 86)',
                                                'rgb(75, 192, 192)',
                                                'rgb(54, 162, 235)',
                                                'rgb(153, 102, 255)',
                                                'rgb(201, 203, 207)'
                                                ],
                                                borde: 1
                                            }]
                                            };
                                        return (<Bar options={options} data={barChartData} />);
                                    }
                                    return null;
                                    
                                })
                            }
                            </div>
                        }
                        <p style={{textAlign:"center", color:"gray"}}>Job Applications received over past week</p>
                </div>
            </Box>
        </>
    )
};