import React, { FunctionComponent, useEffect, useState } from 'react';
import { CompanyClient } from '../../apiClients/companyClient';
import { CompanyModel } from '../../types/CompanyModel';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { SideBarMenu } from '../Dashboard/SideBarMenu';
import {Title} from "../../ui/Dashboard/Title";
/* eslint-disable */
{/* @ts-ignore */}
import { Parser } from 'html-to-react';
import { PrimaryButton } from '../../ui/PrimaryButton';


const FieldTitle: FunctionComponent<{text:string}> = ({text}) => {
    return(
        
        <span style={{color:"#7962D2", fontWeight:"bolder"}}>
            {text}
        </span>
        
    )
}


export const CompanyInfo: FunctionComponent<{}> = () => {
    const [company, setCompany] = useState<CompanyModel>();
    const {companyId} :{companyId :any} = useParams();
    


    useEffect(() => {
        async function fetchCompanies() {
            const { isSuccess, data } = await CompanyClient.getByIdAsync(companyId as number);
            if (isSuccess && data) {
                setCompany(data);
            }
        }
        fetchCompanies();
    }, []);


    return (
        <>
           <Box style={{margin:"0", padding:"0"}} sx={{ flexGrow: 1, display: { xs: 'none', md: 'inline' } }}>
                <div style={{minHeight:"100vh", display:"flex", justifyContent:"space-between"}}>
                    <SideBarMenu />
                    <div style={{minWidth:"70vw", marginTop:"2rem", marginBottom:"2rem", textAlign:"left", paddingLeft:"0rem", marginRight:"2rem" ,border:"1px solid #f1f1f1"}}>
                        <Title text={company?.name as string}/>
                        
                        <div style={{background:"#F1F1F1", marginTop:"2rem" ,padding:"2rem", marginLeft: "1rem"}}>

                            <div >
                                <img src={company?.companyLogoURL} style={{background:"#d5d5d5", width:"200px", height:"100px", margin:"2rem 2rem 1rem 0rem", borderRadius:"11px"}}/>
                            </div>
                           <p><FieldTitle text='Name'/> :  {company?.name}</p>
                            <p>
                                <FieldTitle text='Website'/> : {company?.webURL}
                            </p>
                            <p>
                                <FieldTitle text='Number of Employees'/> : {company?.numberOfEmployees}
                            </p>
                            <p>
                                <FieldTitle text='Description'/> : <br/> 
                                {Parser().parse(company?.description)}
                            </p>

                            <div style={{margin:"4rem 3rem", marginLeft:"25vw",alignSelf:"center"}}>
                                <PrimaryButton buttonText='Edit' margin="0 0" onClick={()=>{}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'inline', md: 'none' } }} >
                <SideBarMenu/>
                <div style={{minWidth:"70vw", marginTop:"2rem", marginBottom:"2rem", textAlign:"left", paddingLeft:"0rem", marginRight:"2rem" ,border:"1px solid #f1f1f1"}}>
                        <Title text={company?.name as string}/>
                        
                        <div style={{background:"#F1F1F1", marginTop:"2rem" ,padding:"2rem", marginLeft: "1rem"}}>

                            <div >
                                <img src={company?.companyLogoURL} style={{background:"#d5d5d5", width:"200px", height:"100px", margin:"2rem 2rem 1rem 0rem", borderRadius:"11px"}}/>
                            </div>
                           <p><FieldTitle text='Name'/> :  {company?.name}</p>
                            <p>
                                <FieldTitle text='Website'/> : {company?.webURL}
                            </p>
                            <p>
                                <FieldTitle text='Number of Employees'/> : {company?.numberOfEmployees}
                            </p>
                            <p>
                                <FieldTitle text='Description'/> : <br/> 
                                {Parser().parse(company?.description)}
                            </p>

                            <div style={{margin:"3rem 3rem", marginLeft:"30vw"}}>
                                <PrimaryButton buttonText='Edit' margin="0 0" onClick={()=>{}}/>
                            </div>
                        </div>
                    </div>
            </Box>
        </>
    );
};
