/**
 * Throws an error if condition fails.
 * @param condition The condition that is expected to be true.
 * @param message An error message for when the condition fails.
 */
export function invariant(
    condition: boolean,
    message?: string
): asserts condition {
    if (!condition) {
        throw new Error(message);
    }
}
