import React, { useRef, useEffect, FunctionComponent} from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import ElementContainer from './ElementContainer';

interface Props {
    label?: string;
    type: string;
    id: string;
    placeholderText?: string;
    autocompleteTag?: string;
    onChange?: (e:React.SyntheticEvent<HTMLInputElement>) => void;
    required?: boolean;
    value?:any;
}

export const Textbox: FunctionComponent<Props> = ({
    label,
    type,
    id,
    placeholderText,
    autocompleteTag,
    onChange,
    ...props
}) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef && inputRef.current && autocompleteTag) {
            inputRef.current.setAttribute('autocomplete', autocompleteTag);
        }
    }, [autocompleteTag]);

    return (
        <ElementContainer id={id} label={label}>
            <input
                id={id}
                ref={inputRef}
                type={type}
                placeholder={placeholderText}
                css={css({
                    fontFamily: 'Lato, Helvetica Neue, Arial, Helvetica, sans-serif',
                    width: '100%',
                    margin: 0,
                    outline: 0,
                    lineHeight: '1.214em',
                    padding: '.678em 1em',
                    fontSize: '1em',
                    background: '#fff',
                    border: '1px solid rgba(34, 36, 38, .15)',
                    color: 'rgba(0, 0, 0, .65)',
                    borderRadius: '.28rem',
                })}
                onChange={onChange}
                {...props}
            />
        </ElementContainer>
    );
};
