import React, { FunctionComponent } from 'react';
import { JobModel } from '../../types/JobModel';
import { JobFilters } from './JobFilters';

interface props{
    jobs: JobModel[] | undefined;
    setDisplayJobs: any,
    jobFilterIds: any,
    setJobFilterIds :any,
    searchText: any
}

export const LeftGuestPanel: FunctionComponent<props> = ({jobs, setDisplayJobs, jobFilterIds, setJobFilterIds, searchText}) => (
    <>
        <div style={{background:"#F6F6F6"}}>
            <JobFilters  jobs = {jobs} setDisplayJobs = {setDisplayJobs} isHome={true} jobFilterIds={jobFilterIds} setJobFilterIds={setJobFilterIds} searchText={searchText}/>
        </div>
    </>
);
