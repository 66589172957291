import {atom, selector} from 'recoil';
import { JobFilterModel } from 'types/JobFilterModel';
import {JobFilterEnum} from '../enums/JobFilterEnum';
import { JobFilterOptions } from 'types/JobFilterModel';

const defaultFilterValue:JobFilterOptions = {
    [JobFilterEnum.EmploymentType]:[],
    [JobFilterEnum.Location]:[],
    [JobFilterEnum.Category]:[],
    [JobFilterEnum.CompensationType]:[],
    [JobFilterEnum.ExperienceLevel]:[],
    [JobFilterEnum.Skills]:[],
    [JobFilterEnum.EducationLevel]:[],
    [JobFilterEnum.CompensationCurrency]:[],
    [JobFilterEnum.LocationCountry]:[]
}
//Stores all options related to the job filters
export const JobFilterOptionsAtom = atom({
    key:"JOBFILTER_OPTIONS_ATOM",
    default:[] as JobFilterModel[]
});

export const JobFilterOptionsSelector = selector({
    key:"JOBFILTER_OPTIONS_SELECTOR",
    get:()=> null,
    set: ()=>{
        //do something here
    }
});

// for home page
export const JobFilterSelectionsAtom = atom({
    key:"JOBFILTER_ATOM",
    default:defaultFilterValue
});

export const JobFilterSelector = selector({
    key:"JOBFILTER-SELECTOR",
    get:()=> null,
    set: ()=>{
        //set state here        
    }
});

//for dashborad job filtering
export const JobFilterDashboradSelectionsAtom = atom({
    key:"JOBFILTER_DASHBOARD_ATOM",
    default:defaultFilterValue
});

export const JobFilterDashboardSelector = selector({
    key:"JOBFILTER_SELECTOR_DASHBOARD",
    get:()=> null,
    set: ()=>{
        //for setter options         
    }
});
