import React, {FunctionComponent} from 'react';
import ClipLoader from "react-spinners/ClipLoader";

export const Spinner : FunctionComponent = () => {
    return (
       <div style={{alignSelf:"center", textAlign:"center", padding:"4rem 0", alignItems:"center" }}>
                <ClipLoader
                    color={"#7962D2"}
                    loading={true}
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
        </div>
    )
}