import React from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { Layout } from './layout/layout';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import {RecoilRoot} from 'recoil';
import { UserProvider } from './state/UserProvider';

const queryClient = new QueryClient();

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            {/* @ts-ignore */}
           <RecoilRoot>
                <UserProvider>
                    <CssBaseline />
                    <Router>
                        <Layout />
                    </Router>
                </UserProvider>
            </RecoilRoot>
        </QueryClientProvider>
    );
}
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// ReactDOM.render(
//     <App />,
//     document.getElementById('react-app-container'),
// );
