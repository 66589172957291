import React, { FunctionComponent } from 'react';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import { PrimaryButton } from '../../ui/PrimaryButton';
import { useHistory } from 'react-router-dom';

const Showcase: FunctionComponent = () => {
    const history = useHistory();
    return (
        <>
            <Box sx={{ flexGrow: 1, display: { xs: 'inline', md: 'none' } }} >
                <div style={{padding:"0 1.1rem", marginBottom:"6rem", marginTop:"5rem"}}>

                  <h2 style={{textAlign:"left"}}>Some Title here</h2>
                        <p style={{ fontSize:"16", textAlign:"justify"}}>Lorem ipsu.cumque consectetur harum explicabo voluptatem, sint cupiditate iure repellat velit laboriosam, eveniet fugiat nisi quisquam in.</p>
                        <div style={{marginTop:"30px", textAlign:"left"}}>
                            <PrimaryButton
                                margin="0 0"
                                buttonText='Find Jobs'
                                onClick = {()=>{}}
                            />
                            <Button variant="contained" style={{backgroundColor:"#D9D9D9",color:"black" ,marginLeft:"20px"}}>Create Job Post</Button>
                        </div> 
                </div>
            </Box>
            <Box style={{margin:"0", padding:"0"}} sx={{ flexGrow: 1, display: { xs: 'none', md: 'inline' } }}>
                <div style={{height: "375px", display:"flex", justifyContent:"center", alignItems:"center", padding:"0px 60px 20px 60px", background:"#F6F6F6"}}>
                    <div style={{textAlign:"justify", padding:"0px 3rem"}}>
                        <h2>Some Title here</h2>
                        <p style={{paddingRight:"10rem", fontSize:"16"}}>Lorem ipsu.  consectetur harum explicabo voluptatem, sint cupiditate iure su.  consectetur harum explicabo voluptatem, sint cupiditate iure repellat velit laboriosam, eveniet fugiat nisi quisquam in.</p>
                        <div style={{marginTop:"30px"}}>
                            <PrimaryButton
                                margin="0 0"
                                buttonText='Find Jobs'
                                onClick = {()=>{}}
                            />
                            <Button 
                                variant="contained" 
                                style={{backgroundColor:"#D9D9D9",color:"black" ,marginLeft:"20px"}}
                                onClick={()=>{history.push("/createjob")}}
                            >
                                Create Job Post
                            </Button>
                        </div>
                    </div>
                    <div style={{borderRadius:"15px", minWidth:"20vw", marginRight:"7rem"}}>
                        <img alt="showcase-image" src={"/Images/showcase.jpg"} height="270px" width="auto"/>
                    </div>
                </div>
            </Box>
        </>
    )
};

export default Showcase;