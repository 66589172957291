import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

// @ts-ignore
function ElementContainer({children,id,label}) {
  return (
    <div css={css({
      clear: 'both',
      margin: '.5em 0 1em',
      padding: '0 1.5em',
      flex: 1,
    })}
    >
      {label
                && (
                <label
                  htmlFor={id}
                  css={css({
                    textAlign: 'left',
                    fontSize: '12px',
                    display: 'inherit',
                    color: '#7962D2',
                    lineHeight: '15px',
                    padding: '7px 0',
                    fontWeight: 'bold',
                  })}
                >
                  {label}
                </label>
                )}

      <div css={css({
        fontSize: '1em',
        fontWeight: '400',
        color: 'rgba(0,0,0,.87)',
        textAlign: 'left',
      })}
      >
        {children}
      </div>
    </div>
  );
}

export default ElementContainer;
