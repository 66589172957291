import React,{FunctionComponent,useEffect,useState} from 'react';
import { useParams } from 'react-router';
import {  JobForm } from './JobForm';
import { useQuery } from 'react-query';
import { JobClient } from '../../apiClients/jobClient';
import { JobModel } from '../../types/JobModel';
import Box from '@mui/material/Box';
import { SideBarMenu } from '../Dashboard/SideBarMenu';
import {Title} from "../../ui/Dashboard/Title";



export const EditJob : FunctionComponent<{}>=()=>{
    const {jobId}:{jobId :string|undefined} = useParams();
    const [jobData, setJobData] = useState<JobModel[]>();

  
    const {isLoading, isSuccess, data} = useQuery('JobById',()=>JobClient.getByIdAsync(Number(jobId)));

    useEffect(()=>{
         if(isSuccess && !isLoading){
            setJobData(data?.data);
         }

    },[isLoading,isSuccess,data]);


    return (
          <>
          <Box style={{margin:"0", padding:"0"}} sx={{ flexGrow: 1, display: { xs: 'none', md: 'inline' } }}>
                <div style={{minHeight:"100vh", display:"flex", justifyContent:"space-between"}}>
                    <SideBarMenu />
                    <div style={{minWidth:"70vw", marginTop:"2rem", marginBottom:"2rem", textAlign:"left", paddingLeft:"0rem", marginRight:"2rem" ,border:"1px solid #f1f1f1"}}>
                        <Title text="Edit Job"/>
                        <JobForm formData={jobData}/>
                    </div>
                </div>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'inline', md: 'none' } }} >
                <SideBarMenu/>
                <div style={{padding:"0 1rem", marginBottom:"3rem", marginTop:"0rem", border:"1px solid #f1f1f1"}}>
                    <Title text="Edit Job"/>
                </div>
                    <JobForm formData={jobData}/>
            </Box>
        </>            
    );
};