import React,{FunctionComponent, useEffect} from 'react'
import { useState } from 'react';
import { JobClient } from '../../apiClients/jobClient';
import { JobModel } from 'types/JobModel';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
/* eslint-disable */
{/* @ts-ignore */}
import { Parser } from 'html-to-react';
import { Box, Button }  from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import { localStorageDataUtil } from '../../utils/localStoarageUtil';

const ApplyButton: FunctionComponent<{apply: any}> = ({apply}) => {
    return (
        <>
            <Button variant="contained" style={{backgroundColor:"#7962D2", color:"white", fontSize:"0.75rem", textAlign:"center"}} onClick={apply}>
                Apply
            </Button>
        </>
    )
}

const JobDetails =()=>{
    const {jobId} :{jobId :any} = useParams();
    const [job, setJob] = useState<JobModel>({} as JobModel);
    const history = useHistory();
    const jobDetails = useQuery(['jobDetails',jobId],async ({queryKey}) =>
       JobClient.getByIdAsync(queryKey[1] as number)
    );
    useEffect(() => {
    async function fetchJobs() {
      if(jobDetails.isSuccess && !jobDetails.isLoading){
        if(jobDetails?.data.data)
        {
          setJob(jobDetails?.data.data as any)
        }
      }
    }
    fetchJobs();
   
    }, [jobDetails.isLoading,jobDetails.isSuccess,jobDetails,setJob]);

    const isUserVerified = () : boolean =>{
        const localData = localStorageDataUtil();        
        if(localData?.verified){
            return true;
        }
        return false;
    }

    const getDate = (data: any) => {
        const myDate = new Date(data);
        const result = myDate.getFullYear().toString() +"-"+ myDate.getMonth().toString() +"-"+myDate.getDate().toString();
        return result;
    }

    const onApply = () =>{
        const res = isUserVerified();
        if(res){
            history.push(`/apply/${job.jobID}/${job.title}`)
        }
        else{
            alert("Please Verify your account first!");
        }
    }
    return (
        <div style={{background:"#F6F6F6"}}>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'inline' } }} >
            <h2 style={{textAlign:"center", color:"#7962D2"}}>Job Detail</h2>
            <div style={{minHeight:"90vh", display:"flex", justifyContent:"space-between"}}>
                
                <div style={{minWidth:"65vw", marginTop:"2rem", marginBottom:"2rem", textAlign:"left", paddingLeft:"5rem"}}>
                    <p>Job Details</p>
                    <h2 style={{color:"#7962D2"}}>{job?.title}</h2>
                    <Button variant="contained" style={{backgroundColor:"#D5D3DE", color:"black", fontSize:"0.75rem"}}>
                        <FacebookIcon style={{borderRadius:"50%"}}/>
                        <span style={{width:"5px"}}> </span>
                        Share
                    </Button>

                    <div style={{minHeight:"30vh",  marginTop:"2rem", marginRight:"10%"}}>
                         {Parser().parse(job.description)}
                    </div>
                    <div style={{marginLeft:"0.5 rem", marginTop:"3rem"}}>
                        <ApplyButton apply={()=>{onApply()}} />
                    </div>


                </div>
                <div style={{border:"1px solid white"}}>
                    <div style={{border:"1px solid #f3f3f3", height:"650px", width:"3o0px", margin:"2rem 5rem", borderRadius:"15px", padding:"0 0", overflow:"hidden", background:"#ECECEC"}}>
                            <div >
                                <img src={job?.company?.companyLogoURL} style={{background:"#d5d5d5", width:"200px", height:"100px", margin:"2rem 2rem 0 2rem", borderRadius:"11px"}}/>
                            </div>
                            <p style={{color:"#7962D2", cursor:"pointer", fontSize:"13px", marginTop:"10px", textAlign:"center"}}>visit website</p>
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>

                            <ApplyButton apply={()=>{onApply()}} />
                            </div>
                            <div style={{height:"2.5rem"}}></div>
                            <div style={{borderBottom:"1px solid gray", textAlign:"left",margin:"0 2rem", marginTop:"4rem", paddingBottom:"1rem",  marginBottom:"1rem"}}>
                                <h4 style={{margin:"2px 0", color:"gray"}}>Compensation</h4>
                                <h4 style={{margin:"2px 0"}}>Rs. {job?.compensation}</h4>
                            </div>
                            <div style={{borderBottom:"1px solid gray", textAlign:"left",margin:"0 2rem", paddingBottom:"1rem",  marginBottom:"1rem"}}>
                                <h4 style={{margin:"2px 0", color:"gray"}}>Positions</h4>
                                <h4 style={{margin:"2px 0"}}>{job?.positionsAvailable} openings</h4>
                            </div>
                            <div style={{ textAlign:"left",margin:"0 2rem", paddingBottom:"1rem",  marginBottom:"1rem"}}>
                                <h4 style={{margin:"2px 0", color:"gray"}}>Apply Berfore</h4>
                                <h4 style={{margin:"2px 0"}}>{getDate(job?.applicationEndDate)}</h4>
                            </div>
                    </div>
                </div>
            </div>
        </Box>

         <Box sx={{ flexGrow: 1, display: { xs: 'inline', md: 'none' } }} >
            <h2 style={{textAlign:"center", color:"#7962D2"}}>Job Detail</h2>
            <div style={{minHeight:"90vh", display:"flex", justifyContent:"space-between", flexDirection:"column"}}>
                
                <div style={{minWidth:"65vw", marginTop:"2rem", marginBottom:"2rem", textAlign:"left", paddingLeft:"2rem"}}>
                    <p>Job Details</p>
                    <h2 style={{color:"#7962D2"}}>{job?.title}</h2>
                    <Button variant="contained" style={{backgroundColor:"#D5D3DE", color:"black", fontSize:"0.75rem"}}>
                        <FacebookIcon style={{borderRadius:"50%"}}/>
                        <span style={{width:"5px"}}> </span>
                        Share
                    </Button>

                    <div style={{minHeight:"30vh",  marginTop:"2rem", marginRight:"10%"}}>
                        {/* <p> */}
                         {Parser().parse(job.description)}
                        {/* </p> */}
                    </div>
                    <div style={{marginLeft:"0.5 rem", marginTop:"3rem"}}>

                        <Button variant="contained" style={{backgroundColor:"#7962D2", color:"white", fontSize:"0.75rem", textAlign:"center"}} onClick={()=>{
                            onApply();                         
                            }}>Apply for this posiiton</Button>
                    </div>
                </div>
                <div style={{border:"1px solid white"}}>
                    <div style={{border:"1px solid #f3f3f3", height:"650px", width:"3o0px", margin:"2rem 5rem", borderRadius:"15px", padding:"0 0", overflow:"hidden", background:"#ECECEC"}}>
                        {/* <div> */}
                            <div style={{background:"#d5d5d5", width:"200px", height:"100px", margin:"2rem 2rem 0 2rem", borderRadius:"11px"}}>

                            </div>
                            <p style={{color:"#7962D2", cursor:"pointer", fontSize:"13px", marginTop:"10px", textAlign:"center"}}>visit website</p>
                           
                            <div style={{height:"2.5rem"}}></div>
                            <div style={{borderBottom:"1px solid gray", textAlign:"left",margin:"0 2rem", marginTop:"4rem", paddingBottom:"1rem",  marginBottom:"1rem"}}>
                                <h4 style={{margin:"2px 0", color:"gray"}}>Job-Type</h4>
                                <h4 style={{margin:"2px 0"}}>Full-Time</h4>
                            </div>
                            <div style={{borderBottom:"1px solid gray", textAlign:"left",margin:"0 2rem", paddingBottom:"1rem",  marginBottom:"1rem"}}>
                                <h4 style={{margin:"2px 0", color:"gray"}}>Location</h4>
                                <h4 style={{margin:"2px 0"}}>Kathmandu, Nepal</h4>
                            </div>
                            <div style={{ textAlign:"left",margin:"0 2rem", paddingBottom:"1rem",  marginBottom:"1rem"}}>
                                <h4 style={{margin:"2px 0", color:"gray"}}>Apply Berfore</h4>
                                <h4 style={{margin:"2px 0"}}>11 Sept, 2022</h4>
                            </div>
                    </div>
                </div>
            </div>
        </Box>

        </div>
    )
}
export default JobDetails;
