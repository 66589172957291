import React, { FunctionComponent } from 'react';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useHistory } from 'react-router-dom';


const sideBarMenus = [
    {
        title:"My Profile",
        link:"/profile",
        id:6
    },
    {
        title: "My Jobs",
        link: "/jobs/401",
        id: 1
    },
    {
        title: "My Applications",
        link: "/applied",
        id: 2
    },
    {
        title: "My Companies",
        link: "/company",
        id: 3
    },
    {
        title: "Settings",
        link: "/settings",
        id: 4
    }
    
];

export const SideBarMenu: FunctionComponent = () => {
    
    const [open, setOpen] = React.useState(false);
        
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
        console.log("##",event);
        if (reason !== 'backdropClick') {
        setOpen(false);
        }
    };

    const history = useHistory();

    return (
	<>
		<Box style={{margin:"0", padding:"0"}} sx={{ flexGrow: 1, display: { xs: 'none', md: 'inline' } }}>
            <div style={{border:"1px solid white", width:"420px"}}>
                <div style={{border:"1px solid #f3f3f3", height:"550px", width:"300px", margin:"2rem 5rem", borderRadius:"15px", padding:"0 0", overflow:"hidden", background:"#ECECEC"}}>
                    {/* <div style={{background:"#d5d5d5", width:"190px", height:"100px", margin:"2rem 2rem 1rem 3rem", borderRadius:"11px"}}> */}
                        {/* <img src="/Images/gk-logo.png" alt="" width="190px"/> */}
                    {/* </div> */}
                    
                    <div style={{height:"5rem"}}></div>
                    {
                        sideBarMenus?.map((each : any) => {
                            return(
                                <div style={{borderBottom:"1px solid gray", textAlign:"left",margin:"0 2rem", marginTop:"0.9rem", paddingBottom:"0.5rem",  marginBottom:"0.5rem", cursor:"pointer"}}>
                                    <h4 style={{margin:"2px 0", color:"gray", padding:"0 2rem"}} onClick={()=>{history.push(each?.link)}}>{each?.title}</h4>
                                </div>
                            )
                        })
                    }
                    <div style={{height:"1rem"}}></div>

                    <div style={{display:"flex", justifyContent:"center", alignItems:"left", marginBottom:"1rem", marginTop:"2rem"}}>
                        <Button variant="contained" style={{backgroundColor:"#7962D2", color:"white", fontSize:"0.75rem"}} onClick={()=>{history.push("/createjob")}}>Create a new Job</Button>
                    </div>
                    
                </div>
            </div>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: 'inline', md: 'none' } }} >
            <div style={{padding:"0 1rem", marginBottom:"1rem", marginTop:"1rem", border:"1px solid #f1f1f1"}}>
                        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                            <div>
                                {/* <img src="/Images/gk-logo.png" alt="" height="100px"/> */}
                            </div>
                            {/*  */}
                            <div>
                                <Button 
                                    variant="contained" 
                                    style={{backgroundColor:"#D9D9D9", color:"black", fontSize:"0.75rem"}} 
                                    onClick={handleClickOpen}>
                                        more Options
                                </Button>
                            </div>
                            <Dialog disableEscapeKeyDown open={open} onClose={handleClose} >
                                <DialogTitle>Dashboard Options</DialogTitle>
                                <DialogContent style={{background:"#f1f1f1"}}>
                                <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <div style={{height:"3rem"}}></div>
                                        <div>
                                            {
                                                sideBarMenus?.map((each : any) => {
                                                    return(
                                                        <div style={{borderBottom:"1px solid gray", textAlign:"center",margin:"0 1rem", marginTop:"0.3rem", paddingBottom:"1rem",  marginBottom:"1rem", cursor:"pointer"}}>
                                                            <h4 style={{margin:"2px 0", color:"gray"}} onClick={()=>{history.push(each?.link)}}>{each?.title}</h4>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <br />
                                </Box>
                                </DialogContent>
                                <DialogActions>
                                <Button onClick={handleClose}>Back</Button>
                                </DialogActions>
                            </Dialog>
                            {/*  */}
                        </div>
                </div>
                 <div style={{display:"flex", justifyContent:"center", alignItems:"center", marginBottom:"1rem"}}>
                    <Button 
                        variant="contained" 
                        style={{backgroundColor:"#7962D2", color:"white", fontSize:"0.95rem", width:"90%"}} 
                        onClick={()=>{history.push("/createjob")}}>
                            Create a new Job
                        </Button>
                </div>
                <div style={{display:"flex", justifyContent:"center", alignItems:"center", marginBottom:"1rem"}}>
                    <Button 
                        variant="contained" 
                        style={{
                            backgroundColor:"#7962D2", 
                            color:"white", 
                            fontSize:"0.95rem", 
                            width:"90%"
                        }} 
                        onClick={()=>{history.push("/addCompany")}}>
                            Add a new Company
                    </Button>
                </div>
        </Box>
	</>
)};
