import { JobClient } from "../../apiClients/jobClient";
import React, { FunctionComponent, useEffect, useState } from "react";
import { JobModel } from "types/JobModel";
import { LeftGuestPanel } from "./LeftGuestPanel";
import { JobCards } from "./JobCard";
import { SearchBar } from "./SearchBar";
import { useLocation } from "react-router-dom";
import queryString from 'query-string';
import { Box, Button }  from "@mui/material";
import Pagination from '@mui/material/Pagination';
import { useHistory } from 'react-router-dom';
import { JobFilterBar } from '../Job/JobFilterBar';


const PAGE_SIZE = 5;

export const SearchPage: FunctionComponent = () => {
    const [jobs, setJobs] = useState<JobModel[]|undefined>([]);
    const [displayJobs, setDisplayJobs] = useState<JobModel[]>([]);
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const [jobFilterIds, setJobFilterIds] = useState<Number[]>([]);
    const [searchText, setSearchText] = useState("");
    const [pageNum, setPageNum] = useState<any>(1);
    const [totalCount, setTotalCount] = useState<any>(1);
    const history = useHistory();
    const [count, setCount] = useState(0);
    const [openFilters, setOpenFilters] = useState<boolean>(false);

    useEffect(()=>{
        async function fetchPaginatedResults(){
            const result = await JobClient.getPaginatedJobs(
                {
                    numOfRows: PAGE_SIZE,
                    pageNum: queryParams?.pageNum,
                    searchText: queryParams?.title,
                    jobFilters: jobFilterIds
                }
            );

            setDisplayJobs(result?.data?.data);
            setTotalCount(result?.data?.count);
        }
        fetchPaginatedResults();
    },[count]);

    useEffect(()=>{
        // reconstructing data from query params
        if(Boolean(queryParams?.title))
        {
            setSearchText(queryParams?.title as string);
        }
        if(Boolean(queryParams?.filters))
        {
            const f = queryParams?.filters as string;
            const stringArray = f.split(',');
            // Use the map() function to convert each string to an integer
            const intArray = stringArray.map(string => parseInt(string, 10));
            setJobFilterIds(intArray);   
        }
        if(Boolean(queryParams?.pageNum))
        {
            const num:any = queryParams?.pageNum;
            setPageNum(num);
        }
    },[queryParams]);

   

    const handleChange =async (event:any, newPage:any) => {
        console.log(event);
        const f:String = jobFilterIds.join(",");
        // @ts-ignore
        history.push({pathname:"/search/results", search:"?"+ new URLSearchParams({title: queryParams?.title as string, filters: f, pageNum:newPage.toString()}).toString()});
        setCount(count+1);
    }
    return (
        <div>   
            <SearchBar key={"abx"} title="Search Results" params ={queryParams} setJobs={setJobs} showFilters={false} isInit={true} jobFilterIds={jobFilterIds} setJobFilterIds={setJobFilterIds} searchText={searchText} setSearchText={setSearchText}/>
            <JobFilterBar />
             <Box style={{margin:"0", padding:"0"}} sx={{ flexGrow: 1, display: { xs: 'none', md: 'inline' } }}>
                <div style={{ display:"flex",background:"#F6F6F6", alignItems:"center", justifyContent:"center"}}>
                    {
                        (displayJobs.length > 0) ? 
                        (<div style={{margin:"0"}}>
                            <JobCards displayJobs={displayJobs}/>
                            <div style={{marginTop:"2rem"}}>
                                <Pagination style={{display:"flex", justifyContent:"center"}} count={Math.ceil(totalCount/PAGE_SIZE)} page={pageNum} onChange={handleChange} variant="outlined" shape="rounded" />
                            </div>
                        </div>) : (
                            <div style={{margin:"0 7vw"}}>
                                <h2 style={{textAlign:"center", color:"#7962D2"}}>
                                    Sorry! No jobs found related to this search. 
                                </h2>
                            </div>

                        )
                    }
                </div>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'inline', md: 'none' } }} >
                <Button variant="contained" style={{background:"#D9D9D9", color:"black", marginLeft:"10vw"}} onClick={()=>{setOpenFilters(!openFilters)}}> Filters</Button>
                {
                (openFilters) && (<div style={{marginLeft:"-6rem" ,marginTop:"-0.5rem"}}>
                    <LeftGuestPanel jobs = {jobs} setDisplayJobs = {setDisplayJobs} jobFilterIds={jobFilterIds} setJobFilterIds={setJobFilterIds} searchText={searchText}/>
                </div>)
                }
                {
                        (displayJobs.length > 0) ? 
                        (   <div>
                                <JobCards displayJobs={displayJobs}/>
                                <div style={{marginTop:"2rem"}}>
                                    <Pagination style={{display:"flex", justifyContent:"center"}} count={Math.ceil(totalCount/PAGE_SIZE)} page={pageNum} onChange={handleChange} variant="outlined" shape="rounded" />
                                </div>
                            </div>
                        ) 
                        : (
                            <div style={{padding:"2rem 3rem"}}>
                                <h2 style={{textAlign:"center", color:"#7962D2"}}>
                                    Sorry! No jobs found related to this search. 
                                </h2>
                            </div>

                        )
                    }
               
            </Box>
        </div>
    )
}