import React, { FunctionComponent} from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Editor } from '@tinymce/tinymce-react';
import {config} from '../../config';

interface Props {
    label?: string;
    id: string;
    onChange?: any;
    value?:string;
}

export const TextEditor : FunctionComponent<Props> = ({
    label,
    id,
    onChange,
    value,
    ...props
}) =>{
    return (
         <div style={{margin:"5px 20px"}}>
                        <label
                            htmlFor={"description-label"}
                            css={css({
                                textAlign: 'left',
                                fontSize: '12px',
                                display: 'inherit',
                                color: '#7962D2',
                                lineHeight: '15px',
                                padding: '7px 0',
                                fontWeight: 'bold',
                            })}>
                                {label}
                        </label>
                        <Editor
                            apiKey={config.tinyMceApiKey}
                            init={{
                                branding: false,
                                menubar: false,
                                statusbar: false,
                                placeholder: "Your Description here ...",
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                    'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                                ],
                                toolbar: 'undo redo | blocks | ' +
                                    'bold italic forecolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat | help',
                                                    }}
                            value={value}
                            onEditorChange={onChange}
                            {...props}
                        />
                    </div>
    );
};