import { JobClient } from '../../apiClients/jobClient';
import React, { FunctionComponent } from 'react';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import { SideBarMenu } from '../Dashboard/SideBarMenu';
import {Title} from "../../ui/Dashboard/Title";
import { useHistory } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useParams } from 'react-router-dom';
import { EmptyResultText } from '../../ui/EmptyResultText';


const StatusDictionary : any = {
    "401": "Active",
    "402": "Closed",
    "403": "Drafted"
};

export const Jobs: FunctionComponent = () => {
    const [jobs, setJobs] = React.useState<any>([]);
    const {jobStatusId} :{jobStatusId :any} = useParams();
    const history = useHistory();

    const [age, setAge] = React.useState(String(jobStatusId));

    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value as string);
        const val = event.target.value as string;
        if(val == "401"){
            history.push("/jobs/401");
        }
         if(event.target.value == "402"){
            history.push("/jobs/402")
        }
         if(event.target.value == "403"){
            history.push("/jobs/403")
        }
    };

    
     React.useEffect(() => {
        async function fetchUserJobs() {
            const { isSuccess, data, status } = await JobClient.getByUserAsync();
            if(status === 401){
                history.push("/login");
                return null;
            }
            if (isSuccess && data) {
                const filtered = data?.filter((x:any)=>x.jobStatus === Number(jobStatusId));
                setJobs(filtered);
            }
            return null;
        }
        fetchUserJobs();
    },[jobStatusId]);

    const handleDelete = async (id: number)=>{
        const response = await JobClient.updateJobStatusAsync({"id":id,"status":402});
        if(response?.isSuccess){
            const filteredData = jobs?.filter((each:any)=>each.jobID != Number(id));
            setJobs(filteredData);
            alert("Sucessfully Deleted")
        }else{
            alert("Something went wrong deleting the job");
        }
    };
    return(
        <>
            <Box style={{margin:"0", padding:"0"}} sx={{ flexGrow: 1, display: { xs: 'none', md: 'inline' } }}>
                <div style={{minHeight:"100vh", display:"flex", justifyContent:"space-between"}}>
                    <SideBarMenu />
                   
                    <div style={{minWidth:"70vw", marginTop:"2rem", marginBottom:"2rem", textAlign:"left", paddingLeft:"0rem", marginRight:"2rem" ,border:"1px solid #f1f1f1"}}>
                            <Title text="Created Jobs List"/>
                            <Button   
                                variant="contained" 
                                style={{backgroundColor:"#7962D2", color:"white", fontSize:"0.75rem", marginBottom:"1rem",marginLeft:"2rem"}} 
                                onClick={()=>{history.push("/createjob")}}
                            >
                                Post a New Job
                            </Button>
                            <div style={{width:"80%", alignSelf:"center", margin:"0rem 2rem"}}>
                             <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Jobs</InputLabel>
                                <Select
                                    style={{background:"#d3d3d3", color:"black"}}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={age}
                                    label="Jobs"
                                    onChange={handleChange}
                                >
                                <MenuItem key={401} value={401}>Active</MenuItem>
                                <MenuItem key={402} value={402}>Closed</MenuItem>
                                <MenuItem key={403} value={403}>Draft</MenuItem>
                                </Select>
                            </FormControl>
                            <br /><br />
                            </div>

                            {
                                (jobs.length === 0) &&     
                                (
                                    <EmptyResultText text={"Sorry, No Jobs available. You can start creating one."} />
                                )
                                
                            }
                            {
                                jobs?.map((each:any)=>{
                                    return(
                                        <div style={{borderBottom:"1px solid gray",background:"#f1f1f1", borderRadius:"10px", padding:"1rem 3rem", marginBottom:"10px"}}>
                                            <h3 style={{marginBottom:"0px"}}>{each?.title}</h3>
                                            {(each?.jobStatus === 401)&&(
                                                <span style={{textAlign:"right",fontSize:"0.85rem", fontWeight:"bold", color:"green"}}>{StatusDictionary[each?.jobStatus as string]}</span>

                                            )}
                                            {(each?.jobStatus === 402)&&(
                                                <span style={{textAlign:"right",fontWeight:"bold",fontSize:"0.85rem", color:"tomato"}}>{StatusDictionary[each?.jobStatus as string]}</span>

                                            )}
                                            {(each?.jobStatus === 403)&&(
                                                <span style={{textAlign:"right",fontWeight:"bold",fontSize:"0.85rem", color:"blue"}}>{StatusDictionary[each?.jobStatus as string]}</span>

                                            )}
                                            <p style={{fontSize:"0.7rem", marginTop:"1px", color:"gray"}}>Positions Available: {each?.positionsAvailable} | Compensation: {each?.compensation}</p>
                                            <div>
                                                <Button   
                                                    variant="contained" 
                                                    style={{backgroundColor:"#7962D2", color:"white", fontSize:"0.75rem", marginRight:"1rem"}} 
                                                    onClick={()=>{history.push("/jobdetails/"+ each?.jobID)}}
                                                >
                                                    View Details
                                                </Button>
                                                <Button   
                                                    variant="contained" 
                                                    style={{backgroundColor:"#7962D2", color:"white", fontSize:"0.75rem", marginRight:"1rem"}} 
                                                    onClick={()=>{history.push("/jobapplication/job/"+ each?.jobID)}}
                                                >
                                                    View Applications
                                                </Button>
                                                <Button   
                                                    variant="contained" 
                                                    style={{backgroundColor:"gray", color:"white", fontSize:"0.75rem", marginRight:"1rem"}} 
                                                    onClick={()=>{history.push("/edit/"+ each?.jobID)}}
                                                >
                                                    Edit Job
                                                </Button>
                                                <Button   
                                                    variant="contained" 
                                                    style={{backgroundColor:"tomato", color:"white", fontSize:"0.75rem"}} 
                                                    onClick={()=>{handleDelete(each?.jobID)}}
                                                >
                                                    Delete 
                                                </Button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                    </div>
                </div>
            </Box>
             <Box sx={{ flexGrow: 1, display: { xs: 'inline', md: 'none' } }} >
                <SideBarMenu/>
                <div style={{padding:"0 1rem", marginBottom:"3rem", marginTop:"0rem", border:"1px solid #f1f1f1"}}>
                     <Title text="Created Company List"/>
                </div>
                <Button   
                    variant="contained" 
                    style={{backgroundColor:"#7962D2", color:"white", fontSize:"0.75rem", marginBottom:"1rem",marginLeft:"2rem"}} 
                    onClick={()=>{history.push("/createjob")}}
                >
                    Post a new Job
                </Button>
                <div>
                     {
                                jobs?.map((each:any)=>{
                                    return(
                                        <div  style={{borderBottom:"1px solid gray",background:"#f1f1f1", borderRadius:"10px", padding:"1rem 3rem", marginBottom:"10px"}}>
                                            <h3 style={{marginBottom:"0px"}}>{each?.title}</h3>
                                            <p style={{fontSize:"0.7rem", marginTop:"1px", color:"gray"}}>Positions Available: {each?.positionsAvailable} | Compensation: {each?.compensation}</p>
                                            <div>
                                                <Button   
                                                    variant="contained" 
                                                    style={{backgroundColor:"#7962D2", color:"white", fontSize:"0.75rem", marginRight:"1rem"}} 
                                                    onClick={()=>{history.push("/jobdetails/"+ each?.jobID)}}
                                                >
                                                    View Details
                                                </Button>
                                                 <Button   
                                                    variant="contained" 
                                                    style={{backgroundColor:"#7962D2", color:"white", fontSize:"0.75rem", marginRight:"1rem"}} 
                                                    onClick={()=>{history.push("/jobapplication/job/"+ each?.jobID)}}
                                                >
                                                    View Applications
                                                </Button>
                                                <Button   
                                                    variant="contained" 
                                                    style={{backgroundColor:"gray", color:"white", fontSize:"0.75rem", marginRight:"1rem"}} 
                                                    onClick={()=>{}}
                                                >
                                                    Edit Job
                                                </Button>
                                                <Button   
                                                    variant="contained" 
                                                    style={{backgroundColor:"tomato", color:"white", fontSize:"0.75rem"}} 
                                                    onClick={()=>{handleDelete(each?.jobID)}}
                                                >
                                                    Delete 
                                                </Button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                </div>
            </Box>

        </>
    )
}
