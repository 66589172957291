import React, { FunctionComponent, useState, useEffect } from 'react';
import { Box, Button, TextField } from "@mui/material";
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { JobClient } from '../../apiClients/jobClient';
import { useLocation } from 'react-router-dom';
import Select from 'react-select'

const MIN_VALID_SEARCH_LENGTH = 3;


export const SearchBar: FunctionComponent<{title: string, params?: any, setJobs?: any, jobs?:any, showFilters?:any, isInit?:any, jobFilterIds: any, setJobFilterIds: any,searchText:any,setSearchText:any}> = ({title, params= null,setJobs=null, jobFilterIds,setJobFilterIds,searchText,setSearchText}) =>{
    const history  = useHistory();
    const [queryText, setQueryText] = useState(params?.title);
    const [, setLocation] = useState(Boolean(params?.location)?params.location: "");
    const [searchDisabled, setSearchDisabled] = useState(true);
    const [jobLocations, setJobLocations] = useState<any[]>([]);
    const [searchResult, setSearchResult] = useState<any>([]);
    useEffect(()=>{
        console.log("ASDFASDF", setSearchText);
        console.log("RRR", searchText);
    },[])

    const {isSuccess,data} = useQuery(['search',searchText],async ({queryKey})=>{
            if(queryKey[1].length>=3){
                const res =  JobClient.getSearchTextAsync(queryKey[1]);
                setSearchResult(res);
                return res;
            }
            return null
        });
    const loc = useLocation();

    useEffect(()=>{
        console.log("####", searchResult);
    },[searchResult])

    const [, setIsInitial] = useState(loc.pathname === "search/results");
     const handleLocationSelect = (selectedOption:any)=>{
        setJobFilterIds([...jobFilterIds, selectedOption?.value]);
    }
    
    useEffect(()=>{
        async function fetchLocation(){
            const result = await JobClient.getAllJObLocations();
            let datas : any[] = [];
            result?.data?.map((each:any)=>{
                datas.push({
                    value: each?.id,
                    label: each?.label
                })
            });
            setJobLocations(datas);
        };

		if(setJobs != null){
            if (isSuccess && data && (searchText.length>=MIN_VALID_SEARCH_LENGTH)) {
                setJobs(data?.data)
                setIsInitial(false);
            }
        }

        fetchLocation();
    },[])


    const handleSearch = (queryText:any) => {
        if (queryText.length>=MIN_VALID_SEARCH_LENGTH) {
            const filterText = jobFilterIds.join(",");
            history.push({pathname:"/search/results", search:"?"+ new URLSearchParams({title: queryText, filters: filterText, pageNum:"1"}).toString()});
		}
        else{
            alert("Use more than 3 letters for searching.");
        }
    }
    return (
        <>
            <Box sx={{ flexGrow: 1, display: { xs: 'inline', md: 'none' } }} >
                <div style={{background:"#F6F6F6", margin:"0", padding:"0 1rem" , paddingBottom:"2rem"}}>
                    <h2 style={{textAlign:"center", color:"#7962D2"}}>{title}</h2>
                    <br/>
                    <div style={{display:"flex",marginTop:"50px", marginBottom:"4rem", height:"3rem", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>

                        <TextField fullWidth style={{margin:"5px 5px",width:"400px", background:"white"}}  label="Skill, Company or Title" id="fullWidth" variant="outlined"/>
                        <TextField fullWidth style={{margin:"5px 5px",width:"400px", background:"white"}} label="Location" id="fullWidth" variant="outlined" onChange={(e)=>{setLocation(e.target.value)}}/>
                        <Button variant="contained" style={{width:"400px", height:"50px", background:"#7962D2"}}>Search</Button>
                    </div>
                </div>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'inline' } }} >
            
                <div style={{background:"#F6F6F6", margin:"0", paddingBottom:"2rem"}}>
                    <h2 style={{textAlign:"center", color:"#7962D2"}}>{title}</h2>
                    <div style={{display:"flex", margin:"50px 7rem",marginBottom:"0px", height:"3rem", alignItems:"center", justifyContent:"center"}}>
                        <TextField 
                            fullWidth 
                            style={{margin:"0 20px", background:"white",width:"80%"}}  
                            label="Skill, Company or Title" id="fullWidth" 
                            variant="outlined" 
                            value={queryText} 
                            onChange={(e)=>{
                                setQueryText(e.target.value);
                                setSearchDisabled(!(e.target.value.length >= MIN_VALID_SEARCH_LENGTH));
                                }} 
                        />
                        <div style={{width:"900px", marginRight:"1rem"}}>
                            <Select
                                label="Location" 
                                placeholder="Location"
                                options = {jobLocations} 
                                styles={{
                                    control: (baseStyles) =>{ //, state
                                    // console.log(state);
                                    return ({
                                    ...baseStyles,
                                    padding:"0.5rem 10px"
                                })},
                                }}
                                onChange ={handleLocationSelect}
                            />
                        </div>
                        <Button variant="contained" style={{width:"400px", height:"50px", background:"#7962D2", color:"white"}} onClick={()=>{
                            handleSearch(queryText);
                            }} disabled={searchDisabled}>
                            Search Jobs
                        </Button>
                    </div>
                    
                </div>
            </Box>       
        </>
    )
}

