import React, {FunctionComponent} from 'react';

interface Props {
    text : string
}
export const EmptyResultText : FunctionComponent<Props> = ({text}) => {
    return (
        <>
            <h4 style={{color:"gray", textAlign:"center", marginTop:"3rem"}}>
                {text}
            </h4>
        </>
    )
}