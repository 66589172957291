import React, { FunctionComponent, useEffect } from 'react';
import { Body } from './body';
import { Footer } from './Footer';
import { useUserDispatcher } from '../state/hooks/useUserDispatcher';
import { useUserState } from '../state/hooks/useUserState';
import ResponsiveAppBar from '../components/Job/Navbar';
import ScrollToTop from './ScrollToTop';
import { localStorageDataUtil } from '../utils/localStoarageUtil';

export const Layout: FunctionComponent = () => {

    const { setUserLogin } = useUserDispatcher();
    const { selectUser } = useUserState();

    useEffect(() => {
        const user = selectUser();

        if (!user) {
            const strStoredUser = window.localStorage?.getItem('user');
            const storedUser = strStoredUser && JSON.parse(strStoredUser || '');
            const localData = localStorageDataUtil();
            const token = localData?.token;

            if (storedUser && token) {
                setUserLogin(storedUser);
            }
        }
    }, []);

    return (
        <div style={{margin:0, padding:0, overflow:"hidden"}}>
            <ScrollToTop/>
            <ResponsiveAppBar />
            <Body />
            <Footer />
        </div>
    );
};
