import React, { FunctionComponent, useState } from 'react';
import { LeftGuestPanel } from '../Job/LeftGuestPanel';
import { useQuery } from 'react-query';
import { JobClient } from '../../apiClients/jobClient';
import { JobModel } from '../../types/JobModel';
import { JobCards } from '../Job/JobCard';
import { SearchBar } from '../Job/SearchBar';
import Showcase from '../Job/Showcase';
import { Box, Button }  from "@mui/material";
// import { JobFilterBar } from '../../components/Job/JobFilterBar';

export const Home: FunctionComponent = () => {
    const [jobs, setJobs] = useState<JobModel[]|undefined>([]);
    const [displayJobs, setDisplayJobs] = useState<JobModel[]|any>([]);
    const [dummyJobs, setDummyJobs] = useState<JobModel[]|undefined>([]);
    const [jobFilterIds, setJobFilterIds] = useState<Number[]>([]);
    const [searchText, setSearchText] = useState("");
    const [openFilters, setOpenFilters] = useState<boolean>(false);

    React.useEffect(()=>{},[searchText]);
    
    useQuery(
        'Jobs',
        ()=>{
            return JobClient.getPaginatedJobs({pageNum:1, numOfRows: 10, jobFilters:[], searchText:""})
        },
        {
            onSuccess: (data)=>{
                setJobs(data?.data?.data);
                setDisplayJobs(data?.data?.data);
                console.log(dummyJobs);
            }
        }
    );
    return(
        <>
            <Showcase/>
            <SearchBar title="Find Jobs" jobFilterIds={jobFilterIds} setJobFilterIds={setJobFilterIds} searchText={searchText} setSearchText={setSearchText}/>
            {/* <JobFilterBar /> */}
            {/* <JobFilterBar /> */}
            <Box style={{margin:"0", padding:"0"}} sx={{ flexGrow: 1, display: { xs: 'none', md: 'inline' } }}>
                <div style={{minHeight:"90vh", display:"flex",background:"#F6F6F6", justifyContent:"center", alignItems:"center"}}>
                        <div>
                            <JobCards displayJobs={displayJobs}/>
                        </div>
                </div>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'inline', md: 'none' } }} >
                <Button variant="contained" style={{background:"#D9D9D9", color:"black", marginLeft:"10vw"}} onClick={()=>{setOpenFilters(!openFilters)}}> Filters</Button>
                {
                (openFilters) && (<div style={{marginLeft:"-6rem" ,marginTop:"-0.5rem"}}>
                    <LeftGuestPanel jobs = {jobs} setDisplayJobs = {setDummyJobs} jobFilterIds={jobFilterIds} setJobFilterIds={setJobFilterIds} searchText= {searchText}/>
                </div>)
                }
                <JobCards displayJobs={displayJobs}/>
            </Box>

        </>
)};