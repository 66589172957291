export enum JobFilterEnum {
    EmploymentType = 101,
    Location = 102,
    Category = 103,
    CompensationType = 104,
    ExperienceLevel = 105,
    Skills = 106,
    EducationLevel = 107,
    CompensationCurrency = 108,
    LocationCountry  = 109,
    Unknown =0,
}

export default JobFilterEnum;
