import React, {
    FunctionComponent, useEffect, useState,
} from 'react';
import { CompanyTypeOption } from 'types/CompanyType';
import { FormContainer } from '../../ui/form/FormContainer';
import { Textbox } from '../../ui/form/Textbox';
import { Dropdown } from '../../ui/form/Dropdown';
import { CompanyClient } from '../../apiClients/companyClient';
// import { useFormResponse } from '../../hooks/useFormResponse';
import { EntityType } from 'types/EntityType';
import { useQuery } from 'react-query';
import { TextEditor } from '../../ui/form/TextEditor';
import { postAsync} from "../../apiClients/uploadClient";
import UploadTypeEnum from "../../enums/UploadTypeEnum";
import { useHistory } from 'react-router-dom';
import { Button }  from "@mui/material";
import { SecondaryButton } from '../../ui/SecondaryButton';
import { FormAlert } from '../../ui/form/FormAlert';
import { MessageTypeEnum } from '../../enums/MessageTypeEnum';
import { DashFormContainer } from '../../components/Auth/UserFormContainer';


export const CompanyForm: FunctionComponent<{}> = () => {
    const [companyTypeOptions, setCompanyTypeOptions] = useState<CompanyTypeOption[]|any>([]);
    const [companyTypeValue, setCompanyTypeValue] = useState<string|any>();
    const {isLoading, isSuccess, data} = useQuery('Company Type',()=>CompanyClient.getCompanyTypesAsync());
    const [companyDescription, setCompanyDescription] = useState("");
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [logoFile, setLogoFile] = useState<any>();
    const [severity, setSeverity] = useState<MessageTypeEnum>(MessageTypeEnum.Unknown);
    const [alertMessage, setAlertMessage] = useState<string>("");
    const history = useHistory();

    useEffect(() => {
        if(isSuccess && !isLoading){
             const options = data?.data?.map((companyType: EntityType): CompanyTypeOption => (
                { value: companyType.id, label: companyType.description }
            ));
            setCompanyTypeOptions(options);
        }
       
    }, [isLoading,isSuccess, data]);
    
    const handleEditorChange = (content:string) => {
        setCompanyDescription(content);
    }

    
    const uploadFile =async (file:any, uploadType:any, companyId: any)=>{
        const formData = new FormData();
        formData.append('File',file);
        formData.append('UploadType', uploadType);
        formData.append('CompanyID',companyId);
        const response = await postAsync(formData);
        if(uploadType === UploadTypeEnum.CompanyLogo && response?.isSuccess){
            return response?.data;
        }
        return false;
    };
    function scrollToTop(){
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

    }

    function validate(data:any) : boolean {
        let isValidated = true;
        if(!Boolean(data.companyTypeID)){
            setSeverity(MessageTypeEnum.Warning);
            setAlertMessage("Comany Type is required.");
            isValidated = false;
        }else if(data.name === "" || !Boolean(data.name)){
            setSeverity(MessageTypeEnum.Warning);
            setAlertMessage("Comany Name is required.");
            isValidated = false;

        }
        else if(data.numberOfEmployees<0){
             setSeverity(MessageTypeEnum.Warning);
             setAlertMessage("Number of employees is required.");
             isValidated = false;
        }
        else if(!Boolean(data.webURL)){
            setSeverity(MessageTypeEnum.Warning);
            setAlertMessage("Please provide a web URL.");
            isValidated = false;

        }
        else if(!Boolean(data.description)){
            setSeverity(MessageTypeEnum.Warning);
            setAlertMessage("Description cannot be empty.");
            isValidated = false;

        }
        return isValidated;
    }

    async function handleSumbit(e: React.SyntheticEvent) {
        e.preventDefault();
        
        const target = e.target as typeof e.target & {
            companyName: { value: string };
            companyTypeValue: { value: string };
            companyNumberOfEmployees: { value: string };
            description: {value: string};
            webURL: {value: string};
        }
        let newCompany: any = {
            companyTypeID: Number(companyTypeValue),
            name:target.companyName.value,
            numberOfEmployees: parseInt(target.companyNumberOfEmployees.value, 10),
            webURL: target.webURL.value,
            description: companyDescription
        }
        const isValidated = validate(newCompany);
        if(!isValidated){
            scrollToTop();
        } else {
            const { isSuccess, data } = await CompanyClient.postAsync(newCompany);
            if(data === null){
                setSeverity(MessageTypeEnum.Error);
                setAlertMessage("Error Creating your Company.");
            }
            if (isSuccess && isFileSelected){
                uploadFile(logoFile, UploadTypeEnum.CompanyLogo, data);
            }
            if (isSuccess && data) {
                alert("Company Added Sucessfully!");
                newCompany["companyID"] = Number(data);
                history.goBack();
                
            } else {
                setSeverity(MessageTypeEnum.Warning);
                setAlertMessage("Something went wrong please try again later!");
                scrollToTop();
            }
        }
        
    }

    const handleOnChange = (option: React.SyntheticEvent<HTMLInputElement>|any) => {
        setCompanyTypeValue(option.value);
    };

    return (
        // @ts-ignore
        <DashFormContainer>
            <FormContainer handleSubmit={handleSumbit} >
                    <FormAlert 
                        severity={severity} 
                        message={alertMessage} 
                    />
                    <Dropdown 
                        label="Company Type" 
                        id="companyType" 
                        options={companyTypeOptions} 
                        value={[companyTypeValue]}
                        onChange={handleOnChange} 
                    />

                    <Textbox
                        label="Company Name"
                        type="text"
                        id="companyName"
                        required
                    />

                    <Textbox
                        label="Number of Employees"
                        type="number"
                        id="companyNumberOfEmployees"
                        required
                    />

                    <Textbox
                        label="Web URL"
                        type="text"
                        id="webURL"
                        required
                    />
                    <Textbox id="logo" label="Company Logo" type="file"  onChange={(event:any)=>{
                        setLogoFile(event.target.files[0]);
                        setIsFileSelected(true);
                    }}/>
                    <TextEditor label="Job Description" id="Job_Description" onChange={handleEditorChange}/>
                    <div style={{marginTop:"2rem"}}>
                        <Button 
                            type = "submit"
                            variant="contained" 
                            style={{backgroundColor:"#7962D2", color:"white", fontSize:"0.75rem", textAlign:"center", margin:"0 2rem"}} 
                        >
                            Add Company
                        </Button>
                        <SecondaryButton
                            buttonText="Cancel"
                            onClick={()=>{history.push("/company")}}
                        />
                    </div>
            </FormContainer>
        </DashFormContainer>
    );
};
