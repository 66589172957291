import React, { FunctionComponent } from 'react';
import { Switch, Route } from 'react-router-dom';

import { Home } from '../components/Home/Home';
import { Login } from '../components/Auth/Login';
import { SignUp } from '../components/Auth/SignUp';
import { Contact } from '../components/Contact/Contact';
import { Jobs } from '../components/Job/Jobs';
import { Settings } from '../components/Settings/Settings';
import { Logout } from '../components/Auth/Logout';
import { useUserDispatcher } from '../components/../state/hooks/useUserDispatcher';
import { UserModel } from '../types/UserModel';
import { EditJob } from '../components/Job/EditJob';
import { CreateJob } from '../components/Job/CreateJob';
import {AppliedJob} from '../components/JobApplication/AppliedJobs';
import {ApplicationByJob} from '../components/JobApplication/ApplicationByJob';
import JobApplicationDetail from '../components/JobApplication/JobApplicationDetail';
import JobDetails from '../components/Job/JobDetails';
import { JobApplication } from '../components/JobApplication/JobApplication';
import { SearchPage } from '../components/Job/SearchPage';
import { MyProfile } from '../components/Profile/ProfilePage';
import { VerifyEmail } from '../components/Email/VerifyEmail';
import { ResendVerificationLink } from '../components/Email/ResendVerificationLink';
import { ResponsiveDashBoard } from '../components/Dashboard/ResponsiveDashBoard';
import UpdateApplicationStatus from '../components/JobApplication/UpdateApplicationStatus';
import { DashboardCompany } from '../components/Company/DashboardCompany';
import { PostCompany } from '../components/Company/PostCompany';
import ForgetPassword from '../components/Email/ForgetPassword';
import { CompanyInfo } from '../components/Company/CompanyInfo';
import ResetPassword from '../components/Email/ResetPassword';
import { EditCompany } from '../components/Company/EditCompany';


export const Body: FunctionComponent = () => {
    const { setUserLogin, setUserSignUp } = useUserDispatcher();

    const handleOnAuthenticate = (user: UserModel) => {
        setUserLogin(user);
    };

    const handleOnSignUp = (user: UserModel) => {
        setUserSignUp(user);
    }

    return (
        <div style={{marginTop:"10vh", padding:"2rem 0rem", background:"#F6F6F6", overflow:"hidden"}}>
            <Switch>
                <Route exact path="/">
                    <Home />
                </Route>
                <Route path="/login">
                    <Login onAuthenticate={handleOnAuthenticate}
                    />
                </Route>
                <Route path="/signup">
                    <SignUp onSignUp={handleOnSignUp} />
                </Route>
                <Route path="/contact">
                    <Contact title="" />
                </Route>

                <Route path="/applied" exact>
                    <AppliedJob />
                </Route>

                <Route path="/jobapplication/job/:jobId" exact>
                    <ApplicationByJob />
                </Route>

                <Route path="/dashboard/:filterId">
                    <ResponsiveDashBoard/>
                </Route>
                <Route path="/addCompany">
                    <PostCompany/>
                </Route>
                <Route path="/company">
                    <DashboardCompany/>
                </Route>
                <Route path="/companyDetails/:companyId">
                    <CompanyInfo/>
                </Route>
                <Route path="/editCompany/:companyId">
                    <EditCompany/>
                </Route>
                <Route path="/myprofile">
                    <MyProfile />
                </Route>
                <Route path="/jobs/:jobStatusId">
                    <Jobs />
                </Route>
                <Route path="/search/results">
                    <SearchPage />
                </Route>
                <Route path="/jobdetails/:jobId" >
                    <JobDetails/>
                </Route>
                <Route path="/settings">
                    <Settings />
                </Route>
                 <Route path="/profile">
                    <MyProfile />
                </Route>
                <Route path="/logout">
                    <Logout />
                </Route>
                <Route path="/edit/:jobId">
                    <EditJob />
                </Route>
                <Route path="/createjob">
                    <CreateJob  />
                </Route>
                <Route path="/apply/:jobId/:title">
                    <JobApplication/>
                </Route>
                <Route  path="/application-status-update/:jobApplicationId">
                    <UpdateApplicationStatus/>
                </Route>
                <Route path="/application-detail/:jobApplicationId">
                    <JobApplicationDetail />
                </Route>
                <Route path="/verify-email/:confirmationToken">
                    <VerifyEmail />
                </Route>
                <Route path="/resendVerificationLink">
                    <ResendVerificationLink />
                </Route>
                <Route path="/forgetPassword">
                    <ForgetPassword />
                </Route>
                <Route path="/reset-password/:resetToken">
                    <ResetPassword />
                </Route>
            </Switch>
        </div>
    );
};
