import { Button, TextField }  from "@mui/material";
import { postAsync} from "../../apiClients/uploadClient";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useState } from "react";
import { userProfileClient } from "../../apiClients/userProfileClient";
import UploadTypeEnum from "../../enums/UploadTypeEnum";
import { Textbox } from "../../ui/form/Textbox";
import { UserClient } from "../../apiClients/userClient";
import { getUploadbyId } from "../../apiClients/uploadClient";
import { localStorageDataUtil } from "../../utils/localStoarageUtil";


export const MyInformation = ()=>{
    
    
    const [selectedResume, setSelectedResume] = useState();
    const [selectedCoverletter, setSelectedCoverletter] = useState<any>();
    const [resumeId, setResumeId] = useState<any>();
    const [coverletterId,setCoverletterId] = useState<any>();
    const [isResumeSelected, setIsResumeSelected] = useState(false);
    const [isCoverLetterSelected, setIsCoverLetterSelected] = useState(false);
    const [resumeFile, setResumeFile] = useState<any>();
    const [coverletterFile, setCoverletterFile] = useState<any>();

    const history = useHistory();

    const [description, setDescription] = useState("");

    useEffect(()=>{
        async function fetchUserProfile() {
            const res = await UserClient.getUserProfileAsync();
            const data = res?.data;
            if(res?.isSuccess)
            {
                setResumeId(data?.resumeID);
                setCoverletterId(data?.coverletterID);
                setDescription(data?.description);
            }
            if(res?.status === 401){
                history.push("/login");
                return null;
            }
            const cover = await getUploadbyId(data?.coverletterID);
            const resume = await getUploadbyId(data?.resumeID);
            if(resume?.isSuccess){
                setResumeFile(resume?.data);
            }
            if(cover?.isSuccess){
                setCoverletterFile(cover?.data);
            }
            return null;
        }
        fetchUserProfile();
    },[])
    const user = localStorageDataUtil();
    

    const [profilePic] = useState("this is my url to profile pic.");

    const uploadFile =async (file:any, uploadType:any)=>{
        const formData = new FormData();
        formData.append('File',file);
        formData.append('UploadType', uploadType);
        formData.append('CompanyID',"");
        const response = await postAsync(formData);
        if(uploadType === UploadTypeEnum.Resume && response?.isSuccess){
            setResumeId(response?.data);
            return response?.data;
        }
        if(uploadType === UploadTypeEnum.CoverLetter && response?.isSuccess){
            setCoverletterId(response?.data);
            return response?.data;
        }
        return false;
    };

    const submitApplication= async()=>{
        let res2 = coverletterId;
        let res1 = resumeId;
        if(isCoverLetterSelected){
            console.log("uploading coverletter");
            res2 = await uploadFile(selectedCoverletter, UploadTypeEnum.CoverLetter);
            setCoverletterId(res2);
        }
        if(isResumeSelected){
            console.log("uploading resume");
            res1 = await uploadFile(selectedResume, UploadTypeEnum.Resume);
            setResumeId(res1);
        }
        const result = await userProfileClient.updateUserProfileAsync({
            userID: user.userID,
            description: description,
            profilePictureUrl: profilePic,
            coverletterID: res2,
            resumeID: res1,
        });
        if(result?.status === 401){
            history.push("/login");
        }
        if(result.isSuccess){
            alert("Sucessfully Updated your profie.")
            history.push("/");
        }else{
            alert("Something went wrong updating your profile.");
        }
        
    };

    return (
          <div style={{background:"#f3f3f3", margin:"1rem 1rem", borderRadius:"15px", padding:"1rem 1rem"}}>
            <div style={{minHeight:"100vh", display:"flex", justifyContent:"center", flexDirection:"row-reverse"}}>
                
                <div>
                    <div style={{ display:"flex", flexDirection:"column", color:"gray"}}>
                        <div style={{display:"flex",  alignItems:"center", borderBottom:"1px solid #e3e3e3", padding:"1rem"}}>
                            <span style={{width:"9rem"}}><b>Name</b></span>
                            <TextField style={{margin:"5px 5px",width:"400px", background:"#E2E2E2", color:"black"}} size="small"  value={user?.firstName + " " + user?.lastName} id="fullWidth" variant="outlined" disabled/>
                        </div>
                        <div style={{display:"flex",  alignItems:"center", borderBottom:"1px solid #e3e3e3", padding:"1rem"}}>
                            <span style={{width:"9rem"}}><b>Email</b></span>
                            <TextField style={{margin:"5px 5px",width:"400px", background:"#E2E2E2", color:"black"}} size="small"  value={user?.email} id="fullWidth" variant="outlined" disabled/>
                        </div>

                            <span style={{marginLeft:"1rem"}}><b>Resume: </b><a href="#">{resumeFile?.filename}</a></span>
                            <Textbox id="resume" type="file"  onChange={(event:any)=>{
                                setSelectedResume(event.target.files[0]);
                                setIsResumeSelected(true);
                            }}/>
                            
                            <span style={{marginLeft:"1rem"}}><b>Coverleter: </b> <a href="#">{coverletterFile?.filename}</a></span>
                            <Textbox id="coverletter" type="file" onChange={(event:any)=>{
                                setSelectedCoverletter(event.target.files[0]);
                                setIsCoverLetterSelected(true);
                            }}/>

                        <div style={{display:"flex",  justifyContent:"center", flexDirection:"column", width:"100%", padding:"1rem"}}>
                            <p style={{width:"9rem"}}><b>Description</b></p>
                            <TextField style={{margin:"5px 5px", background:"white", color:"black", width:"80%", padding:"5px 0"}} size="small"  label="" id="fullWidth" variant="outlined" 
                                value = {description}
                                onChange={(e)=>{setDescription(e.target.value)}}
                                multiline rows={"9"} />
                        </div>

                    </div>

                    <div style={{ marginRight:"50%", display:"flex", justifyContent:"center", alignItems:"center"}}>

                        <Button variant="contained" style={{backgroundColor:"#7962D2", color:"white", fontSize:"0.75rem", textAlign:"center", textTransform:"none"}} onClick={()=>submitApplication()}>
                            Save My Information</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};