import React, { FunctionComponent, useState } from "react";
import {JobApplicationClient} from '../../apiClients/jobApplicationClient';
import { useHistory, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import { SideBarMenu } from '../Dashboard/SideBarMenu';
import {Title} from "../../ui/Dashboard/Title";


export const ApplicationByJob:FunctionComponent<{}>=()=>{
    const {jobId} :{jobId :any} = useParams();

    const [appliedJobs, setAppliedJobs] = useState<any>([]);
    React.useEffect(() => {
        async function fetchApplications() {
            const { isSuccess, data } = await JobApplicationClient.getJobApplicationByJobId(jobId as number);
            if (isSuccess && data) {
                setAppliedJobs(data);
            }
        }

        fetchApplications();
    }, [jobId]);
    const history = useHistory();

    return (
        <>
            <Box style={{margin:"0", padding:"0"}} sx={{ flexGrow: 1, display: { xs: 'none', md: 'inline' } }}>
                <div style={{minHeight:"100vh", display:"flex", justifyContent:"space-between"}}>
                    <SideBarMenu />
                    <div style={{minWidth:"70vw", marginTop:"2rem", marginBottom:"2rem", textAlign:"left", paddingLeft:"0rem", marginRight:"2rem" ,border:"1px solid #f1f1f1"}}>
                        <Title text={"Applications"}/>
                        <div>
                            {appliedJobs?.map((each:any)=>{
                                
                                return (
                                    <div key={each?.jobApplicationID} style={{borderBottom:"1px solid gray",background:"#f1f1f1", borderRadius:"10px", padding:"1rem 3rem", marginBottom:"10px"}}>
                                        <h3 style={{marginBottom:"0px"}}>{each?.user?.email}</h3>
                                        <p style={{fontSize:"0.7rem", marginTop:"1px", color:"gray"}}> {each?.jobTitle} | Applied Date: {each?.appliedDate} </p>
                                        <div>
                                            <Button   
                                                variant="contained" 
                                                style={{backgroundColor:"#7962D2", color:"white", fontSize:"0.75rem", marginRight:"1rem"}} 
                                                onClick={()=>{history.push("/application-detail/"+each?.jobApplicationID)}}
                                            >
                                                View Application
                                            </Button>
            
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'inline', md: 'none' } }} >
                <SideBarMenu/>
                <div style={{padding:"0 1rem", marginBottom:"3rem", marginTop:"0rem", border:"1px solid #f1f1f1"}}>
                    <Title text="Applications"/>
                    <div>
                        {appliedJobs?.map((each:any)=>{
                            
                            return (
                                <div key={each?.jobApplicationID} style={{borderBottom:"1px solid gray",background:"#f1f1f1", borderRadius:"10px", padding:"1rem 3rem", marginBottom:"10px"}}>
                                    <h3 style={{marginBottom:"0px"}}>{each?.user?.email}</h3>
                                    <p style={{fontSize:"0.7rem", marginTop:"1px", color:"gray"}}> {each?.jobTitle} | Applied Date: {each?.appliedDate} </p>
                                    <div>
                                        <Button   
                                            variant="contained" 
                                            style={{backgroundColor:"#7962D2", color:"white", fontSize:"0.75rem", marginRight:"1rem"}} 
                                            onClick={()=>{history.push("/application-detail/"+each?.jobApplicationID)}}
                                        >
                                            View Application
                                        </Button>
        
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Box>
        </>
    )
};
