import { useHistory } from "react-router-dom";
import React, { FunctionComponent } from 'react';
import Button from "@mui/material/Button";
import { UserClient } from "../../apiClients/userClient";

export const ResendVerificationLink: FunctionComponent = () => {
    const history = useHistory();
    const sendVerificationEmailAsync =async ()=>{
        
        const response =await  UserClient.resendVerificaitonLink();
        if(response.isSuccess){
            alert("Sucessfully confirmed your email")
            history.push("/")
        }else{
            alert("Cannot confirm your email")
        }
    }
    return(
    <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",width:"100%", minHeight:"35vh"}}>
           <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",width:"100%"}}>
                <h2 style={{color:"#1a1a1a"}}> Click the button below to resend you the verification email.</h2>
                <Button variant="contained" style={{backgroundColor:"#7962D2", color:"white"}} onClick={()=>{sendVerificationEmailAsync()}}>
                    Re-send me a link now     
                </Button>
            </div>       
    </div>
)};

