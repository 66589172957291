import React, { FunctionComponent } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import ElementContainer from './ElementContainer';

export interface DropdownOptions {
    value: number;
    label: string;
}

interface Props {
    label: string;
    id: string;
    options: DropdownOptions[] | any;
    creatable?: boolean;
    multiSelectable?: boolean;
    onChange: (option: DropdownOptions|DropdownOptions[]) => void;
    value?:any
}

export const Dropdown: FunctionComponent<Props> = ({
    label,
    id,
    options,
    creatable,
    multiSelectable,
    onChange,
    value=[]
}) => {
    const values = options?.filter((o:any)=>value.includes(o.value));
    return (
        <ElementContainer id={id} label={label}>
            {
                creatable
                    ? (
                        <CreatableSelect
                            isMulti={multiSelectable}
                            options={options}
                        />
                    )
                    : <Select value={values} isMulti={multiSelectable} options={options} onChange={onChange} />
            }

        </ElementContainer>
    );
};
