import React, { FunctionComponent, SyntheticEvent, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormContainer } from '../../ui/form/FormContainer';
import { Textbox } from '../../ui/form/Textbox';
import { useFormResponse } from '../../hooks/useFormResponse';
import { UserModel } from '../../types/UserModel';
import { UserClient } from '../../apiClients/userClient';
import { useMutation } from 'react-query';
import { UserLoginRequest } from '../../types/requests/UserLoginRequest';
import { Button }  from "@mui/material";
import SSOLoginTypeEnum from '../../enums/SSOLoginTypeEnum';
import {config} from '../../config';
import { UserFormContainer } from './UserFormContainer';
import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import { MessageTypeEnum } from '../../enums/MessageTypeEnum';
import { Spinner } from '../../ui/Spinner';


interface Props {
    onAuthenticate: (user: UserModel) => void;
};

export const Login: FunctionComponent<Props> = ({ onAuthenticate }) => {
    const history = useHistory();
    const [btnIsDisabled, setBtnIsDisabled] = useState(false);
    const { handleFormResponse, formResult } = useFormResponse();
    const [isLoading, setIsLoading] = useState(false);

    const authMutation = useMutation((request: UserLoginRequest) =>
        UserClient.authenticateUserAsync(request)
    );

    useEffect(() => {
        if (authMutation.isSuccess && !authMutation.isLoading) {
            setIsLoading(true);
            const data = authMutation?.data;
            const user = data?.data;
            if (data) {
                handleFormResponse(data);
            }

            if (data?.isSuccess && user && user.token) {
                onAuthenticate(user);
                const gk_data = {
                    token: user.token,
                    verified: user.isUserVerified,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email
                };
                
                const stringData = JSON.stringify(gk_data);
                const encodedData = window.btoa(stringData);
                window.localStorage.setItem('gk_data', encodedData);
                setIsLoading(false);
                history.push('/dashboard');
            }
            setIsLoading(false);
        }
    }, [authMutation.isSuccess, authMutation.isLoading])

    async function handleSubmit(e: SyntheticEvent) {
        e.preventDefault();
        setBtnIsDisabled(true);

        const target = e.target as typeof e.target & {
            loginUserInput: { value: string };
            loginPasswordInput: { value: string };
        }

        const request = {
            username: target.loginUserInput?.value,
            password: target.loginPasswordInput?.value,
        };

        authMutation.mutateAsync(request);
        setIsLoading(false);
    }

    const onSuccess = async (res:any) => {
        const decoded : any = jwt_decode(res?.credential);
        const data : any = {
            email: decoded.email,
            token: res.credential,
            SSOType: SSOLoginTypeEnum.Google
        };
        const result = await UserClient.googleAuthenticateAsync(data);
        if(result?.isSuccess)
        {
            const user = result?.data;
            onAuthenticate(user);
            const gk_data = {
                token: user.token,
                verified: user.isUserVerified,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email
            };
            const stringData = JSON.stringify(gk_data);
            const encodedData = window.btoa(stringData);
            window.localStorage.setItem('gk_data', encodedData);
            history.push('/');
        }
        else{
            const message = result?.data;
            if(Boolean(message)){
                alert(message);
            }else{
                alert("Cannot Log you in, Something went wrong.")
            }
        }
    }

    return (
        // @ts-ignore
        <UserFormContainer>
            {(isLoading) &&  
            (
                <Spinner/>
            )}
            {(!isLoading) && (<FormContainer handleSubmit={handleSubmit} title="Login" formResult={formResult}>
                <Textbox
                    label="Email Address"
                    type="email"
                    id="loginUserInput"
                    placeholderText="ram@nepali.com"
                    autocompleteTag="username"
                    onChange={() => setBtnIsDisabled(false)}
                    required
                />

                <Textbox
                    label="Password"
                    type="password"
                    id="loginPasswordInput"
                    autocompleteTag="current-password"
                    onChange={() => setBtnIsDisabled(false)}
                    required
                />
                <Button
                    type="submit"
                    value="Submit"
                    style={{backgroundColor:"#7962D2", color:"white", padding:"0.5rem 1.2rem", marginTop:"1rem"}}
                    disabled={btnIsDisabled}
                >
                    Login
                </Button>

                <div style={{marginTop:"1rem"}}>
                
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <GoogleOAuthProvider clientId={config.googleClientId}>    
                            <GoogleLogin
                                onSuccess={(res:any) => {
                                    onSuccess(res);
                                }}
                                onError={() => {
                                    handleFormResponse({
                                        isSuccess: false,
                                        messages: [{
                                            messageType: MessageTypeEnum.Error,
                                            message: "Google login failed, please try again."
                                        }]
                                    });
                                }}
                                useOneTap
                            />
                        </GoogleOAuthProvider> 
                    </div>
                </div>
                <p style={{color:"#3f3f3f", fontSize:"0.8rem", marginTop:"2rem"}}>
                    <span style={{color:"#7962D2",cursor:"pointer"}} onClick={()=>{history.push("/forgetPassword")}}>Forget Password ?</span>
                </p>
                <p style={{color:"#3f3f3f", fontSize:"0.8rem", marginTop:"2rem"}}>
                    Don't have a account? <span style={{color:"#7962D2",cursor:"pointer"}} onClick={()=>{history.push("/signup")}}>Register now</span>
                </p>
            </FormContainer>)}
        </UserFormContainer>
    );
};

