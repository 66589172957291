import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams, useHistory } from "react-router";
import { JobApplicationClient } from '../../apiClients/jobApplicationClient';
import { config } from '../../config';
import { localStorageDataUtil } from '../../utils/localStoarageUtil';
import { Button, TextField }  from "@mui/material";


const UpdateApplicationStatus  = ()=>{
    const {jobApplicationId} :{jobApplicationId :any} = useParams();
    const [application, setApplication] = useState<any>();
    const [applicationStatus, setApplicationStatus] = useState<any>("501");
    const [isChecked, setIsChecked] = useState(false);
    const [emailContent, setEmailContent] = useState("");

    const jobApplicationDetails = useQuery(['jobApplicationDetails'],async () =>
       JobApplicationClient.getJobApplicationById(jobApplicationId as number)
    );

    useEffect(() => {
        async function fetchJobs() {
        if(jobApplicationDetails.isSuccess && !jobApplicationDetails.isLoading){
            if(jobApplicationDetails?.data.data)
            {
                setApplication(jobApplicationDetails?.data.data as any)
                setApplicationStatus(jobApplicationDetails?.data.data.applicationStatus)
                // console.log("logging...", jobApplicationDetails?.data.data)
            }
        }
    }
    fetchJobs();

    }, [jobApplicationDetails.isLoading,jobApplicationDetails.isSuccess,jobApplicationDetails,setApplication,application]);

    const localData = localStorageDataUtil(); 
    const history = useHistory();

    const handleUpdate = async () => {
        if(applicationStatus === ""){
            alert("Select Application status to update");
            return null;
        }
        const data = {
            jobApplicationId : application?.jobApplicationID,
            jobId : application?.jobID, 
            sendEmail : isChecked,
            emailContent : emailContent,
            applicantEmail : application?.user?.email,
            jobApplicationStatus: applicationStatus
        }
        const response = await JobApplicationClient.UpdateJobApplicationStatus(data);
        if(response?.isSuccess){
            alert("Successfully update.");
            history.push("/");
            return null;
        }
        alert("SomeThing Went wrong.");
        return null;
    }
    return (
        <div  data-testid="details-container" style={{margin:"0px 9rem 0px 2rem",border:"1px solid black", borderRadius:"10px",padding:"1rem 2rem 1rem 2rem",backgroundColor:"#f3f3f3",width:"100%"}}>
           <h2 data-testid="job-title" style={{color:"#7962D2"}}>Job Application Status Update - {application?.jobTitle} </h2>
            <div style={{margin:"2px 25px"}}>
                <p><b>First Name :</b> {application?.user?.firstName}</p>

                <p><b>Last Name :</b> {application?.user?.lastName}</p>
                <p><b>Email :</b> {application?.user?.email}</p>
                <p><b>Resume :</b> {application?.documents[0]?.filename} <a href={`${config.apiBaseUrl}/v1/Download/GetByUploadIdAsync/1`}>download here</a></p>
                
                <p><b>Coverletter :</b> {application?.documents[1]?.filename} <a href={`${config.apiBaseUrl}/v1/Download/GetByUploadIdAsync/1`}>download here</a></p>
                {/* <p><b>Current Status :</b> {application?.applicationStatus}</p> */}
                <br />
                <b>Update Staus to: </b>
                    <select
                        defaultValue={applicationStatus}
                        // value = {applicationStatus}
                        onChange={(e: any )=>{setApplicationStatus(e.target.value);console.log(e.target.value)}}
                    >
                        <option value={"501"}>Pending</option>
                        <option value={"502"}>In Review</option>
                        <option value={"503"}>Rejected</option>
                        <option value={"504"}>Selected</option>
                    </select>

                <br />
                <br />
                <input type="radio" value="Send Email" checked={isChecked} onChange={()=>{setIsChecked(!isChecked)}}/> Send Email
                <br />
                {
                    isChecked &&
                    <TextField style={{margin:"5px 5px", background:"white", color:"black", width:"80%", padding:"5px 0"}} size="small"  label="" id="fullWidth" variant="outlined" 
                            value = {emailContent}
                            onChange={(e:any)=>{setEmailContent(e.target.value)}}
                            placeholder="Your Email Body ..."
                            multiline rows={"9"} />
                }
                <br />
                <br />
                {
                    (localData.email === application?.ownerEmail)
                    &&
                    <Button variant="contained" style={{backgroundColor:"#7962D2", color:"white", fontSize:"0.75rem", textAlign:"center"}} onClick={()=>{
                            handleUpdate();                       
                    }}>Update</Button>

                }

           </div>
       </div>
    );
}

export default UpdateApplicationStatus;