import { UserModel } from 'types/UserModel';
import { useUserStateProvider } from '../UserProvider';

interface useUserStateType {
    selectUser: () => UserModel | null;
}

export const useUserState = (): useUserStateType => {
    const { user } = useUserStateProvider();

    const selectUser = (): UserModel | null => {
        return user;
    };

    return {
        selectUser
    };
};
