import React, { FunctionComponent } from 'react';
import { css } from '@emotion/core';

const styles = {
	row: css({
		display: 'flex',
		flexWrap: 'wrap',
	}),
};

interface Props {
	title: string;
}

export const Contact: FunctionComponent<Props> = ({ title }) => (
	<div css={styles}>
		{title}
	</div>
);
