import React, { FunctionComponent, useState } from 'react';
import { Button }  from "@mui/material";
import Box from '@mui/material/Box';
import { SideBarMenu } from '../Dashboard/SideBarMenu';
import {Title} from "../../ui/Dashboard/Title";

export const Settings: FunctionComponent<{}> = () => {

    const [isJobseeker, setIsJobSeeker] = useState(false);
    const [isEmployer, setIsEmployer] = useState(false);

    return (
         <>
          <Box style={{margin:"0", padding:"0"}} sx={{ flexGrow: 1, display: { xs: 'inline', md: 'inline' } }}>
                <div style={{minHeight:"100vh", display:"flex", justifyContent:"space-between"}}>
                    <SideBarMenu />
                    <div style={{minWidth:"70vw", marginTop:"2rem", marginBottom:"2rem", textAlign:"left", paddingLeft:"0rem", marginRight:"2rem" ,border:"1px solid #f1f1f1"}}>
                        <div>
                            <Title text="Settings"/>

                            <div style={{
                                        fontFamily: 'Lato, Helvetica Neue, Arial, Helvetica, sans-serif',
                                        width: '100%',
                                        margin: "1rem 1rem",
                                        outline: 0,
                                        lineHeight: '1.214em',
                                        fontSize: '1em',
                                        background: '#f1f1f1',
                                        border: '1px solid rgba(34, 36, 38, .15)',
                                        borderRadius: '.28rem',
                                        padding:"2rem 2rem"
                                        
                                    }}
                                    >
                                        <h4>*Please choose the your role</h4>
                                        <div css={{margin:"5px 0px"}} key={"looking for a job"}>
                                                    <input 
                                                        type="checkbox" 
                                                        checked = {isJobseeker}
                                                        onClick = {()=>{setIsJobSeeker(!isJobseeker)}}
                                                    />{"I'm looking for a job"}
                                        </div>
                                        <div css={{margin:"5px 0px"}} key={"post jobs"}>
                                                <input 
                                                    type="checkbox" 
                                                    checked = {isEmployer}
                                                    onClick = {()=>{setIsEmployer(!isEmployer)}}
                                                />{"I'm here to post job"}
                                    </div>
                                    <Button 
                                        variant="contained" 
                                        style={{backgroundColor:"#7962D2", color:"white", marginTop:"1rem"}}
                                    >
                                        Save
                                    </Button>
                            </div>
                                
                        </div>
                    </div>
                </div>
            </Box>
           
   
    </>
)};
