import React, {FunctionComponent} from 'react';
import { Button }  from "@mui/material";

interface Props {
    buttonText : string, 
    onClick : any,
    margin?: string
}

export const PrimaryButton : FunctionComponent<Props> = ({buttonText, onClick, margin = "0 2rem"}) => {
    return (
        <>
            <Button 
                variant="contained" 
                style={{backgroundColor:"#7962D2", color:"white", fontSize:"0.9rem", textAlign:"center", margin: margin}} 
                onClick={onClick}
            >
                {buttonText}
            </Button>
        </>
    )
}