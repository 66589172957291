import { UserModel } from 'types/UserModel';

export interface UserState {
    user: UserModel | null;
}

enum UserActionType {
    Login = 'login',
    Logout = 'logout',
    SignUp = 'signup'
}

export interface UserLoginAction {
    type: UserActionType.Login;
    payload: UserModel;
}

export interface UserLogoutAction {
    type: UserActionType.Logout;
}

export interface UserSignUpAction {
    type: UserActionType.SignUp,
    payload: UserModel;
}

export const UserLogin = (user: UserModel): UserLoginAction => ({
    type: UserActionType.Login,
    payload: user
});

export const UserLogout = (): UserLogoutAction => ({
    type: UserActionType.Logout
});

export const UserSignUp = (user: UserModel): UserSignUpAction => ({
    type: UserActionType.SignUp,
    payload: user
});

export type UserAction =
    | UserLoginAction
    | UserLogoutAction
    | UserSignUpAction;

export const initUserState = (): UserState => ({
    user: null
});

export const makeUserReducer = () => (
    state: UserState,
    action: UserAction
): UserState => {
    switch (action.type) {
        case UserActionType.Login: {
            return {
                ...state,
                user: action.payload
            }
        }
        case UserActionType.Logout: {
            return {
                ...state,
                user: null
            }
        }
        case UserActionType.SignUp: {
            return {
                ...state,
                user: action.payload
            }
        }
        default:
            return state;
    }
}
