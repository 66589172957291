import React, {FunctionComponent} from 'react';
import { Button }  from "@mui/material";

interface Props {
    buttonText : string, 
    onClick : any
}
export const SecondaryButton : FunctionComponent<Props> = ({buttonText, onClick}) => {
    return (
        <>
            <Button 
                variant="contained" 
                style={{backgroundColor:"tomato", color:"white", fontSize:"0.9rem", textAlign:"center", margin:"0 2rem"}} 
                onClick={onClick}
            >
                {buttonText}
            </Button>
        </>
    )
}