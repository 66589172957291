import React, { FunctionComponent, useEffect, useState } from "react";
import { useQuery } from "react-query";
import {JobApplicationClient} from '../../apiClients/jobApplicationClient';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import { SideBarMenu } from '../Dashboard/SideBarMenu';
import {Title} from "../../ui/Dashboard/Title";
import { useHistory } from "react-router-dom";
import { EmptyResultText } from "../../ui/EmptyResultText";



const StatusDict = {
    501: "Applied",
    502: "InReview",
    503: "Rejected",
    504: "Selected"
}
export const AppliedJob:FunctionComponent<{}>=()=>{

    const [appliedJobs, setAppliedJobs] = useState([]);

    useEffect(()=>{
        console.log(appliedJobs);
    },[appliedJobs,setAppliedJobs]);

    useQuery(
        'User Job Applications',
        ()=>{
            return JobApplicationClient.getJobApplicationByUserId();
        },
        {
            onSuccess:(data)=>{
            setAppliedJobs(data?.data);
            console.log("ADFAsdf", data?.data)
        }
        }
    );
    const history = useHistory();

    return (
        <>
            <Box style={{margin:"0", padding:"0"}} sx={{ flexGrow: 1, display: { xs: 'none', md: 'inline' } }}>
                <div style={{minHeight:"100vh", display:"flex", justifyContent:"space-between"}}>
                    <SideBarMenu />
                    <div style={{minWidth:"70vw", marginTop:"2rem", marginBottom:"2rem", textAlign:"left", paddingLeft:"0rem", marginRight:"2rem" ,border:"1px solid #f1f1f1"}}>
                        <Title text="Jobs Applied"/>

                        {
                                (appliedJobs.length === 0) && 
                                (
                                    <EmptyResultText text={"Sorry, No applications data available."} />
                                )
                        }
                        {appliedJobs?.map((each:any)=>{
                                const st = each?.applicationStatus;
                                return (
                                    <div key={each?.jobApplicationID} style={{borderBottom:"1px solid gray",background:"#f1f1f1", borderRadius:"10px", padding:"1rem 3rem", marginBottom:"10px"}}>
                                        <h3 style={{marginBottom:"0px"}}>{each?.user?.email}</h3>
                                        <p style={{fontSize:"0.7rem", marginTop:"1px", color:"gray"}}> {each?.jobTitle} | Applied Date: {each?.appliedDate} </p>
                                        {/* @ts-ignore */}
                                        <p>Status: {StatusDict[st]}</p>
                                        <div>
                                            <Button   
                                                variant="contained" 
                                                style={{backgroundColor:"#7962D2", color:"white", fontSize:"0.75rem", marginRight:"1rem"}} 
                                                onClick={()=>{history.push("/jobdetails/"+each?.jobID)}}
                                            >
                                                View Job Details
                                            </Button>
            
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </Box>
        </>
    )
};
