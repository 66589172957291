import React, { FunctionComponent, SyntheticEvent, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/core';
import { FormContainer } from '../../ui/form/FormContainer';
import { Textbox } from '../../ui/form/Textbox';
import { useFormResponse } from '../../hooks/useFormResponse';
import { UserSignupRequest } from '../../types/requests/UserSignupRequest';
import { useMutation } from 'react-query';
import { UserModel } from '../../types/UserModel';
import { UserClient } from '../../apiClients/userClient';
import { Button }  from "@mui/material";
import { UserFormContainer } from './UserFormContainer';
import { Spinner } from '../../ui/Spinner';


const styles = {
    fields: css({
        display: 'flex',
        flexDirection: 'row',
        margin: '0 - .5em 1em',
    }),
};

interface Props {
    onSignUp: (responseData: UserModel) => void;
}

export const SignUp: FunctionComponent<Props> = ({ onSignUp }) => {
    const history = useHistory();
    const [btnIsDisabled, setBtnIsDisabled] = useState(false);
    const [isJobseeker, setIsJobSeeker] = useState(false);
    const [isEmployer, setIsEmployer] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const { handleFormResponse, formResult } = useFormResponse();

    const mutation = useMutation((request: UserSignupRequest) =>
        UserClient.signUpUserAsync(request)
    );

    useEffect(() => {
        if (mutation.isSuccess && !mutation.isLoading) {

            const data = mutation.data;
            const user = data?.data;
            handleFormResponse(data);

            if (data && user?.token) {
                onSignUp(user);
                window.localStorage.setItem('token', user.token);
                window.localStorage.setItem('user', JSON.stringify(user));
                setIsLoading(false);

                history.push('/profile');
            }

        }
    }, [mutation.isLoading, mutation.isSuccess]);

    async function handleSubmit(e: SyntheticEvent) {
        e.preventDefault();
        setIsLoading(true);

        setBtnIsDisabled(true);

        const target = e.target as typeof e.target & {
            signUpEmailAddress: { value: string };
            signUpFirstName: { value: string };
            signUpLastName: { value: string };
            signUpPassword: { value: string };
            signUpConfirmPassword: { value: string };
        }
        
        const request: UserSignupRequest = {
            emailAddress: target.signUpEmailAddress?.value,
            firstName: target.signUpFirstName?.value,
            lastName: target.signUpLastName?.value,
            password: target.signUpPassword?.value,
            confirmPassword: target.signUpConfirmPassword?.value,
            isJobseeker: isJobseeker,
            isEmployer: isEmployer
        };

        if(request.confirmPassword != request.password){
            alert("Password and Confirm Password do not match.");
        }else{
            mutation.mutateAsync(request);
            history.push("/login")
        }
        setIsLoading(false);
    }
    return (
        // @ts-ignore
        <UserFormContainer>
             {(isLoading) &&  
                (
                    <Spinner/>
                )}
            {(!isLoading) && (<FormContainer handleSubmit={handleSubmit} title="Create Account" formResult={formResult}>
            <Textbox
                label="Email Address"
                type="email"
                id="signUpEmailAddress"
                placeholderText="your@email.com"
                autocompleteTag="email-address"
                onChange={() => { setBtnIsDisabled(false); }}
                required
            />

            <div css={styles.fields}>
                <Textbox
                    label="First Name"
                    type="text"
                    id="signUpFirstName"
                    placeholderText="Ram"
                    autocompleteTag="first-name"
                    onChange={() => { setBtnIsDisabled(false); }}
                    required
                />
                <Textbox
                    label="Last Name"
                    type="text"
                    id="signUpLastName"
                    placeholderText="Nepali"
                    autocompleteTag="last-name"
                    onChange={() => { setBtnIsDisabled(false); }}
                    required
                />
            </div>

            <Textbox
                label="Password"
                type="password"
                id="signUpPassword"
                autocompleteTag="new-password"
                onChange={() => { setBtnIsDisabled(false); }}
                required
            />

            <Textbox
                label="Confirm Password"
                type="password"
                id="signUpConfirmPassword"
                autocompleteTag="new-password"
                onChange={() => { setBtnIsDisabled(false); }}
                required
            />
             <div style={{textAlign:"left", marginBottom:"1rem",padding: '.678em 1em', marginLeft:"0.5rem"}} >
            <h4 >Account Settings</h4>
            <div css={{
                fontFamily: 'Lato, Helvetica Neue, Arial, Helvetica, sans-serif',
                width: '100%',
                margin: 0,
                outline: 0,
                lineHeight: '1.214em',
                padding: '.678em 1em',
                fontSize: '1em',
                background: '#fff',
                border: '1px solid rgba(34, 36, 38, .15)',
                borderRadius: '.28rem',
            }}
            >
                <div css={{margin:"5px 0px"}} key={"looking for a job"}>
                            <input 
                                type="checkbox" 
                                checked = {isJobseeker}
                                onClick = {()=>{setIsJobSeeker(!isJobseeker)}}
                            />{"looking for a job"}
                </div>
                 <div css={{margin:"5px 0px"}} key={"post jobs"}>
                            <input 
                                type="checkbox" 
                                checked = {isEmployer}
                                onClick = {()=>{setIsEmployer(!isEmployer)}}
                            />{"post job"}
                </div>
            </div>
        </div>

            <Button
                type="submit"
                value="Submit"
                style={{backgroundColor:"#7962D2", color:"white", padding:"0.5rem 1.2rem"}}
                disabled={btnIsDisabled}
            >Submit</Button>
        </FormContainer>)}
        </UserFormContainer>
    );
}
