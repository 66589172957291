import React, { FunctionComponent } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { useLocation } from "react-router-dom";
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';



export const SelectedFilter: FunctionComponent<{label:string, value: number}> =({label, value})=>{

    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const history = useHistory();
   
    const handleRemove = () => {
        const existingFilters : any = queryParams?.filters;
        const val = JSON.parse(existingFilters);
        const finalRes = val?.filter((each:any)=>each.value !== value);
        const filterString = JSON.stringify(finalRes);
        // @ts-ignore
        const title : string = queryParams.title;
        history.push({pathname:"/search/results", search:"?"+ new URLSearchParams({title: title, filters: filterString, pageNum:"1"}).toString()});

    }
    return(
        <span style={{display:"flex", alignItems:"center", justifyContent:"space-between",border:"1px solid #7962D2", width:"150px", padding:"0.1rem 0.4rem", borderRadius:"5px", background:"#e3e3e3", marginRight:"0.6rem"}}>
            <span style={{fontSize:"1rem", paddingRight:"1rem", color:"#7962D2"}}>
                {label}
            </span>
            <ClearIcon style={{fontSize:"1rem", color:"#7962D2", cursor:"pointer"}} onClick={handleRemove}/>
        </span>
    )
}