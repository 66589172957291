import React, {FunctionComponent} from 'react';
import { EditForm } from './EditForm';
import Box from '@mui/material/Box';
import { SideBarMenu } from '../Dashboard/SideBarMenu';
import {Title} from "../../ui/Dashboard/Title";

const CompanyTitle = () =>{
    return (
        <>
            <Title text="Editing Company"/>
        </>
    )
}

export const EditCompany : FunctionComponent = () => {
    return (
        <>
          <Box style={{margin:"0", padding:"0"}} sx={{ flexGrow: 1, display: { xs: 'none', md: 'inline' } }}>
                <div style={{minHeight:"100vh", display:"flex", justifyContent:"space-between"}}>
                    <SideBarMenu />
                    <div style={{minWidth:"70vw", marginTop:"2rem", marginBottom:"2rem", textAlign:"left", paddingLeft:"0rem", marginRight:"2rem" ,border:"1px solid #f1f1f1"}}>
                        <CompanyTitle/>
                        <EditForm/>
                    </div>
                </div>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'inline', md: 'none' } }} >
                <SideBarMenu/>
                <div style={{padding:"0 1rem", marginBottom:"3rem", marginTop:"0rem", border:"1px solid #f1f1f1", width:"100vw"}}>
                    <CompanyTitle/>
                    <EditForm/>
                </div>
            </Box>
        </>
    )
} 