const getEnvironmentVariable = (environmentVariable: string | undefined): string => {
	if (!environmentVariable) {
		throw new Error(
			`Couldn't find environment variable: ${environmentVariable}`
		);
	} else {
		return environmentVariable;
	}
};

export const config = {
	apiBaseUrl: getEnvironmentVariable(process.env.REACT_APP_GK_API_BASE_URL),
    googleClientId : getEnvironmentVariable(process.env.REACT_APP_GOOGLE_CLIENT_ID),
    tinyMceApiKey : getEnvironmentVariable(process.env.REACT_APP_TINY_MCE_API_KEY)
};