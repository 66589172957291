import api from './api';

export async function postAsync(obj:any) {
    return api.endpoint('Upload/UploadFileAsync').uploadAsync(obj);
}
export async function getUploadsByUser() {
    return api.endpoint('Upload/GetByUserIdAsync').getAsync();
}

export async function getUploadbyId(data: any) {
    return api.endpoint(`Upload/GetByIdAsync/${data}`).getAsync();
}

export default postAsync;
