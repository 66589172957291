import { useHistory, useParams } from "react-router-dom";
import { useState } from "react";
import { Textbox } from "../../ui/form/Textbox";
import { ChangePaswwordRequest } from "../../types/requests/ChangePasswordRequest";
import { UserClient } from "../../apiClients/userClient";
import { UserFormContainer } from '../../components/Auth/UserFormContainer';
import { FormContainer } from '../../ui/form/FormContainer';
import { useFormResponse } from '../../hooks/useFormResponse';
import { PrimaryButton } from "../../ui/PrimaryButton";


const ResetPassword = ()=>{
    const history = useHistory();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const {resetToken}:{resetToken:string} = useParams();
    const { handleFormResponse, formResult } = useFormResponse();


    const onResetPassword=async ()=>{
        if(confirmPassword === password){
            const data : ChangePaswwordRequest = {
                password: password,
                confirmPassword:confirmPassword,
                token: resetToken
            }
            handleFormResponse(data);

            const res = await UserClient.changePasswordAsync(data);
            if(res.isSuccess){
                alert("Successfully changed your password")
                history.push("/login");
            }else{
                alert("Something went wrong, changing your password!")
            }
        }else{
            alert("Password and Confirm Password Do not match!")
        }
    }
    return (
        //  <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",width:"100%"}}>
        // @ts-ignore
        <UserFormContainer>
            <FormContainer handleSubmit={onResetPassword} title="Reset Your Password" formResult={formResult}>

          
                <Textbox
                    type="password"
                    id="Password"
                    placeholderText="Your New Password Here"
                    required
                    value = {password}
                    onChange={(e:React.SyntheticEvent<HTMLInputElement, Event>)=>{setPassword((e.target as HTMLInputElement).value)}}
                />
                <Textbox
                    type="password"
                    id="Confirm Password"
                    placeholderText="Confirm Your New Password Here"
                    required
                    value = {confirmPassword}
                    onChange={(e:React.SyntheticEvent<HTMLInputElement, Event>)=>{setConfirmPassword((e.target as HTMLInputElement).value)}}
                />

                <PrimaryButton 
                    buttonText="Reset"
                    onClick={()=>{onResetPassword()}}/>
            
            </FormContainer>
            </UserFormContainer>
    
    );
}

export default ResetPassword;