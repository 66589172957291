import React, { FunctionComponent } from 'react';
import Box from '@mui/material/Box';

export const UserFormContainer : FunctionComponent = (props:any) => {
    return (
        <>
            <Box sx={{ flexGrow: 1, display: { xs: 'inline', md: 'none' } }} >
                <div style={{margin:"0px -25px"}}>
                    {props.children}
                </div>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'inline' } }} >
                <div style={{margin:"0px 20vw"}}>
                    {props.children}
                </div>
            </Box>
        </>
    );
}

export const DashFormContainer : FunctionComponent = (props:any) => {
    return (
        <>
            <Box sx={{ flexGrow: 1, display: { xs: 'inline', md: 'none' } }} >
                <div style={{margin:"0px -25px"}}>
                    {props.children}
                </div>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'inline' } }} >
                <div style={{margin:"0px 0vw"}}>
                    {props.children}
                </div>
            </Box>
        </>
    );
}