import * as React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import {AppBar, Avatar, Container, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from "@mui/material";
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { localStorageDataUtil } from '../../utils/localStoarageUtil';


const pages = ['Home', 'Jobs'];;
const displaySettings:any = {
    dashboard: "Dashboard",
    company: 'Company',
    applied : "Applied",
    profile: 'My Profile',
    logout: 'Logout',
    settings: 'Settings',
    resendVerificationLink: "Resend Verification LInk"
}

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [settings, setSettings] = React.useState(['profile','dashboard', 'company', 'applied','settings','logout']);

  React.useEffect(()=>{

    const data : string =  window.localStorage.getItem("gk_data") || "";
    if(data.length > 0){
        const decodedData = window.atob(data);
        const finalData = JSON.parse(decodedData);
        if(!finalData?.verified){
            setSettings(['profile','dashboard/7','company', 'applied','settings','resendVerificationLink','logout'])
        }
    }
  },[]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const history =useHistory();
  const localData = localStorageDataUtil();
  const data : any = localData;
  
  return (
    
    <AppBar position="static" style={{background:"#F6F6F6", minHeight:"65px", color:"black",position: "fixed",top: "0",width: "100%", height:"70px", zIndex:"1000"}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} >
                <Typography style={{marginTop:"4px"}}>
                    <Link to="/">
						<img alt="one" src = "/Images/gk-logo.png" height="60px" />
					</Link>
                </Typography>
            </Box>
        

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={()=>{ history.push("/")}}>
                  <Typography style={{textAlign:"center"}}>{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1,  marginLeft:"7rem" }}>
            <Typography  style={{marginTop:"4px",}}>
                <Link to="/">
					<img alt="one" src = "/Images/gk-logo.png" height="60px" />
				</Link>
            </Typography>
        </Box>
        {!Boolean(data) && (<Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1,  marginLeft:"7rem" }}>
            <Button
                key={"login"}
                onClick={()=> {history.push("/login")}}
                style={{marginRight:"0rem", color:"#3f3f3f"}}
            >
                <b style={{fontSize:"13px"}}>Login</b>
            </Button>
        </Box>)}
          <span style={{width:"170px"}}></span>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={()=> {history.push("/")}}
                style={{marginRight:"2rem", color:"#3f3f3f"}}
              >
                <b style={{fontSize:"13px"}}>{page}</b>
              </Button>
            ))}
          </Box>
          {
                !Boolean(data) &&
                (
                  <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                        <Button
                            key={"login"}
                            onClick={()=> {history.push("/login")}}
                            style={{marginRight:"2rem", color:"#3f3f3f"}}
                        >
                            <b style={{fontSize:"13px"}}>Login</b>
                        </Button>

                        <Button
                            key={"login"}
                            onClick={()=> {history.push("/signup")}}
                            style={{marginRight:"2rem", color:"white", background:"#7962D2", textTransform:"none"}}
                        >
                            <b style={{fontSize:"13px"}}>Register</b>
                        </Button>
                    </Box>
                )
                }

          {Boolean(data) && <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} >
                <Avatar alt={data?.firstName} style={{background:"#7962D2"}} src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
                
              { settings.map((setting) => (
                <MenuItem key={setting}>
                    <Link key={setting} to={"/"+(setting==="dashboard"?"dashboard/7":setting)} style={{textDecoration:"none"}}>
                        <Typography style={{textAlign:"center"}}>{displaySettings[setting]}</Typography>
                    </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
