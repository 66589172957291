import { Textbox } from "../../ui/form/Textbox";
import { UserClient } from "../../apiClients/userClient";
import { useState } from "react";
import { PrimaryButton } from "../../ui/PrimaryButton";
import { FormContainer } from '../../ui/form/FormContainer';
import { useFormResponse } from '../../hooks/useFormResponse';
import { UserFormContainer } from '../../components/Auth/UserFormContainer';



const ForgetPassword = ()=>{
    const [email, setEmail] = useState("");
    const { handleFormResponse, formResult } = useFormResponse();

    const sendChangePasswordLink=async ()=>{
        const data = {
            email:email,
            clientURL : "http://localhost:3000"
        }
        handleFormResponse(data);
        
        const res = await UserClient.sendChangePasswordLinkAsync(data);
        if(res.isSuccess){
            alert("Successfully send the link to your email")

        }
        else{
            alert("Cannot Send link, something went wrong! Try again")
        }

    }
    return (
        // @ts-ignore
        <UserFormContainer>
            <FormContainer handleSubmit={sendChangePasswordLink} title="Reset Your Password" formResult={formResult}>
                
                 <p style={{color:"#3f3f3f", fontSize:"0.8rem", marginTop:"2rem"}}>
                    <span style={{color:"#7962D2",cursor:"pointer"}}>
                        Enter your email to send a link to change your password
                    </span>
                </p>

                <Textbox
                    type="text"
                    id="Your email"
                    placeholderText="Your Email Here"
                    required
                    value = {email}
                    onChange={(e:React.SyntheticEvent<HTMLInputElement, Event>)=>{setEmail((e.target as HTMLInputElement).value)}}
                />
                <PrimaryButton
                    buttonText="Send Link"
                    onClick={()=>{
                        sendChangePasswordLink();
                    }}
                />
            </FormContainer>
        </UserFormContainer>
    )
}
export default ForgetPassword;