import React, { FunctionComponent } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useHistory } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import queryString from 'query-string';


export const FilterSelect:FunctionComponent<{options:any, inputLabel:string}> = ({options, inputLabel}) => {
    const [item, setItem] = React.useState('');
    const history = useHistory();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);

    const handleChange = (event: SelectChangeEvent) => {
        let arr = [];
        const existingFilters : any = queryParams?.filters;
        console.log("Existings",existingFilters);
        setItem(event.target.value);
        const current = options?.find((each:any)=>each.value === event.target.value);
        if(Boolean(existingFilters)){

            const val = JSON.parse(existingFilters);
            arr = val.slice(0);
            console.log("VAL old", val);
        }

        console.log("old list",arr);
        console.log("Selected", current);
        const duplicate = arr?.filter((each:any)=> each.value === current.value);
        if(!Boolean(duplicate.length)){
            arr.push(current);
        }
        console.log("Final list", arr);
        const filterString = JSON.stringify(arr);
        // @ts-ignore
        const title : string = queryParams.title;
        history.push({pathname:"/search/results", search:"?"+ new URLSearchParams({title: title, filters: filterString, pageNum:"1"}).toString()});
        setItem('');

    };
    return (
        <FormControl sx={{ m: 1, minWidth: 80 }}>
            <InputLabel id="demo-simple-select-autowidth-label">{inputLabel}</InputLabel>
            <Select
            style={{width:"150px", background:"#d3d3d3"}}
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={item}
            onChange={handleChange}
            autoWidth
            label={inputLabel}
            >
            {options?.map((each:any)=>{
                return (

                    <MenuItem value={each.value}>{each.label}</MenuItem>
                )
            })}
            </Select>
        </FormControl>
    );
}