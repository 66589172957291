import { EntityType } from '../types/EntityType';
import { CompanyModel } from '../types/CompanyModel';
import { WebJson } from '../types/WebJson';
import api from './api';
import { SaveCompanyRequest } from '../types/requests/SaveCompanyRequest';

export class CompanyClient {
    static getAsync = async (): Promise<WebJson<CompanyModel[]>> => {
        return api.endpoint('Company/GetUserCompaniesAsync').getAsync();
    }

    static getByIdAsync = async (id : number): Promise<WebJson<CompanyModel>> => {
        return api.endpoint('Company/GetByIdAsync/'+ id).getAsync();
    }

    static getCompanyTypesAsync = async (): Promise<WebJson<EntityType[]>> => {
        return api.endpoint('Company/GetCompanyTypesAsync').getAsync();
    }

    static postAsync = async (obj: SaveCompanyRequest): Promise<WebJson<CompanyModel>> => {
        return api.endpoint('Company/PostAsync').postAsync(obj);
    }
    static updateAsync = async (obj: SaveCompanyRequest): Promise<WebJson<CompanyModel>> => {
        return api.endpoint('Company/UpdateAsync').postAsync(obj);
    }

    static softDeletAsync = async (id:number): Promise<WebJson<CompanyModel>> => {
        return api.endpoint('Company/DeleteCompany/'+id).postAsync({});
    }
}