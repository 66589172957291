import { UserLoginRequest } from 'types/requests/UserLoginRequest';
import { UserSignupRequest } from 'types/requests/UserSignupRequest';
import { RequestModel } from '../types/requests/RequestModel';
import { UserModel } from '../types/UserModel';
import { WebJson } from '../types/WebJson';
import api from './api';

const ClientURL = "http://localhost:3000";

export class UserClient {
    static authenticateUserAsync = async (userLoginModel: UserLoginRequest): Promise<WebJson<UserModel>> => {
        return api.endpoint('User/AuthenticateAsync').postAsync(userLoginModel);
    };
    static signUpUserAsync = async (userSignUpModel: UserSignupRequest): Promise<WebJson<UserModel>> => {
        return api.endpoint('User/SignUpAsync').postAsync(userSignUpModel);
    };
    static verifyEmailAsync = async (data:RequestModel|any) => {
        return api.endpoint('User/VerifyEmailAsync').postAsync(data);
    };
    static changePasswordAsync = async (data: RequestModel|any)=>{
        return api.endpoint('User/ResetPasswordAsync').postAsync(data);
    };
    static sendChangePasswordLinkAsync = async(data:RequestModel|any)=>{
        return api.endpoint('User/SendChangePasswordLinkAsync').postAsync(data);
    }
    static resendVerificaitonLink = async()=>{
        return api.endpoint('User/ResendVerificationEmail?ClientURL='+ClientURL).postAsync({});
    }
    static getUserProfileAsync = async (): Promise<WebJson<any>> => {
        return api.endpoint('User/GetUserProfileFromUserIdAsync').getAsync();
    };
    static googleAuthenticateAsync = async (data : any): Promise<WebJson<any>> => {
        return api.endpoint('User/SSOLogInAsync').postAsync(data);
    }
}
