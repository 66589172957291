import React, {
	FunctionComponent,
	useContext,
	Context,
	createContext,
} from 'react';
import { invariant } from './assert';

export interface ProviderWrapperProps<T> {
	value: T;
}
export type ProviderWrapper<T> = FunctionComponent<ProviderWrapperProps<T>>;
/**
 * By default, createContext takes a default value that will be used
 * by consumers if there is no context provider that is an ancestor
 * to that consumer. This behavior is sometimes not desirable when
 * there is no sensible use case for using the context without a
 * Provider being defined. This function wraps the context to throw
 * an error when there is no provider for a given consumer.
 * @param context The context to wrap
 */
export const createContextWithoutDefault = <T,>(
	context: Context<T | undefined> = createContext<T | undefined>(undefined),
): [ProviderWrapper<T>, () => T] => {
    {/* @ts-ignore */}
	const Provider: FunctionComponent<ProviderWrapperProps<T>> = ({children,value,}) => {
		return <context.Provider value={value}> {children} </context.Provider>;
	}

	const useProvider = (): T => {
		const props = useContext(context);
		invariant(
			!!props,
			`Ancestor provider for ${context.displayName ?? ''
			} not found when trying to use context.`,
		);
		return props;
	};
	return [Provider, useProvider];
};
