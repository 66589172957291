import {config} from '../config';

export type QueryParams = Record<string, string>;

export function apiUrl(endpoint: string, params?: QueryParams[]) {
	const baseUrl = config.apiBaseUrl;
	const version = 'v1';
	function completeUrl() {
		if (!params || params.length <= 0) return `${endpoint}/`;

		let strParameter = '';

		Object.entries(params).forEach(([key, value]) => {
			strParameter += `${key}=${value}&`;
		});

		if (strParameter.substr(strParameter.length - 1, 1) === '&') {
			strParameter = strParameter.substr(0, strParameter.length - 1);
		}

		return strParameter.length > 0 ? `${endpoint}?${strParameter}` : `${endpoint}/`;
	}

	return `${baseUrl}/${version}/${completeUrl()}`;
}

export default apiUrl;
