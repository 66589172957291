import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import { JobApplicationClient } from '../../apiClients/jobApplicationClient';
import { config } from '../../config';
import { localStorageDataUtil } from '../../utils/localStoarageUtil';
import { Button }  from "@mui/material";
import Box from '@mui/material/Box';
import { SideBarMenu } from '../Dashboard/SideBarMenu';
import {Title} from "../../ui/Dashboard/Title";

const JobApplicationDetail  = ()=>{
    const {jobApplicationId} :{jobApplicationId :any} = useParams();
    const [application, setApplication] = useState<any>();

    const jobApplicationDetails = useQuery(['jobApplicationDetails'],async () =>
       JobApplicationClient.getJobApplicationById(jobApplicationId as number)
    );

    useEffect(() => {
        async function fetchJobs() {
        if(jobApplicationDetails.isSuccess && !jobApplicationDetails.isLoading){
            if(jobApplicationDetails?.data.data)
            {
                setApplication(jobApplicationDetails?.data.data as any)
            }
        }
    }
    fetchJobs();

    }, [jobApplicationDetails.isLoading,jobApplicationDetails.isSuccess,jobApplicationDetails,setApplication,application]);

    const localData = localStorageDataUtil(); 
    const history = useHistory();
    return (
        <>
            <Box style={{margin:"0", padding:"0"}} sx={{ flexGrow: 1, display: { xs: 'none', md: 'inline' } }}>

            <div style={{minHeight:"100vh", display:"flex", justifyContent:"space-between"}}>
                <SideBarMenu />
                <div style={{minWidth:"70vw", marginTop:"2rem", marginBottom:"2rem", textAlign:"left", paddingLeft:"0rem", marginRight:"2rem" ,border:"1px solid #f1f1f1"}}>               
                <Title text="Applicant Detail"/>
                <br />
                <div style={{padding:"0rem 2rem"}}>
                    <p><b>Title :</b> {application?.jobTitle}</p> 
                    <p><b>Company Name :</b> {application?.companyName}</p>
                    <p><b>Applied Date :</b> {application?.appliedDate}</p>
                    <p><b>Applied Date :</b> {application?.applicationStatus}</p>
                    <p><b>Resume :</b> {application?.documents[0]?.filename}</p>
                    <button onClick={
                        async ()=>{
                            await JobApplicationClient.downloadById(application?.documents[0]?.id)
                        }
                    }>Download</button>
                    <a href={`${config.apiBaseUrl}/v1/Download/GetByUploadIdAsync/1`}>download here</a>
                    <p><b>Coverletter :</b> {application?.documents[1]?.filename}</p>

                    <p><b>First Name :</b> {application?.user?.firstName}</p>

                    <p><b>Last Name :</b> {application?.user?.lastName}</p>
                    <p><b>Email :</b> {application?.user?.email}</p>
                    {
                        (localData.email === application?.ownerEmail)
                        &&
                        <Button variant="contained" style={{backgroundColor:"#7962D2", color:"white", fontSize:"0.75rem", textAlign:"center"}} onClick={()=>{
                                history.push("/application-status-update/"+jobApplicationId);                       
                        }}>Update Status</Button>
                    }

            </div>
        </div>
        </div>
        </Box>
         <Box sx={{ flexGrow: 1, display: { xs: 'inline', md: 'none' } }} >
                <SideBarMenu/>
                <div style={{padding:"0 1rem", marginBottom:"3rem", marginTop:"0rem", border:"1px solid #f1f1f1"}}>
                        <Title text="My Companies List"/>
                </div>
                <Button   
                    variant="contained" 
                    style={{backgroundColor:"#7962D2", color:"white", fontSize:"0.75rem", marginBottom:"1rem",marginLeft:"2rem"}} 
                    onClick={()=>{history.push("/addCompany")}}
                >
                    Add a New Company
                </Button>
                <div style={{padding:"0rem 2rem"}}>
                    <p><b>Title :</b> {application?.jobTitle}</p> 
                    <p><b>Company Name :</b> {application?.companyName}</p>
                    <p><b>Applied Date :</b> {application?.appliedDate}</p>
                    <p><b>Applied Date :</b> {application?.applicationStatus}</p>
                    <p><b>Resume :</b> {application?.documents[0]?.filename}</p>
                    <button onClick={
                        async ()=>{
                            await JobApplicationClient.downloadById(application?.documents[0]?.id)
                        }
                    }>Download</button>
                    <a href={`${config.apiBaseUrl}/v1/Download/GetByUploadIdAsync/1`}>download here</a>
                    <p><b>Coverletter :</b> {application?.documents[1]?.filename}</p>

                    <p><b>First Name :</b> {application?.user?.firstName}</p>

                    <p><b>Last Name :</b> {application?.user?.lastName}</p>
                    <p><b>Email :</b> {application?.user?.email}</p>
                    {
                        (localData.email === application?.ownerEmail)
                        &&
                        <Button variant="contained" style={{backgroundColor:"#7962D2", color:"white", fontSize:"0.75rem", textAlign:"center"}} onClick={()=>{
                                history.push("/application-status-update/"+jobApplicationId);                       
                        }}>Update Status</Button>
                    }
                </div>
            </Box>
        </>
    );
}

export default JobApplicationDetail;