import React, { FunctionComponent } from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Box }  from "@mui/material";


export const Footer: FunctionComponent = () => {
	const getYear = (): string => {
		return new Date().getFullYear().toString();
	};
	return (<>
        <Box style={{margin:"0", padding:"0"}} sx={{ flexGrow: 1, display: { xs: 'none', md: 'inline' } }}>
            <div>
                <div style={{minHeight:"30vh", background:"#E3E3E3", display:"flex",  justifyContent:"center", padding:"4rem 2rem"}}>
                    <div style={{padding:"0 11rem"}}>
                            <img src="/Images/gk-logo.png" alt="" height="150px"/>
                            <div style={{marginTop:"10px", textAlign:"center"}}>
                                <FacebookIcon style={{marginRight:"5px"}}/>
                                <TwitterIcon style={{marginRight:"5px"}}/>
                                <InstagramIcon style={{marginRight:"5px"}}/>
                            </div>
                    </div>
                    <div style={{ padding:"0 11rem", fontSize:"1rem"}}>
                            <h2 style={{color:"#7962D2"}}>Sitemap</h2>
                            <p style={{padding:"0.5rem 0", borderBottom:"1px solid gray",color:"gray", fontWeight:"bold", textAlign:"left", cursor:"pointer"}}>About</p>
                            <p style={{padding:"0.5rem 0", borderBottom:"1px solid gray", color:"gray", fontWeight:"bold", textAlign:"left", cursor:"pointer"}}>Home</p>
                            <p style={{padding:"0.5rem 0", borderBottom:"1px solid gray", color:"gray", fontWeight:"bold", textAlign:"left", cursor:"pointer"}}>Contact</p>
                            <p style={{padding:"0.5rem 0", color:"gray", fontWeight:"bold", textAlign:"left", cursor:"pointer"}}>Find Jobs</p>
                    </div>
                    <div style={{ minWidth:"30vw"}}>
                            <h2 style={{color:"#7962D2", textAlign:"center"}}>Site by </h2>
                            
                            <div style={{background:"#251552", margin:"0 5rem", borderRadius:"11px", padding:"1rem 3rem"}}>
                                <img src="/Images/pumori1.png" alt="my-logo" width="170px" />
                            </div>
                    </div>
                </div>
            </div>
        </Box>
        <Box style={{margin:"0", padding:"0"}} sx={{ flexGrow: 1, display: { xs: 'inline', md: 'none' } }}>
                <div>
                    <div style={{minHeight:"30vh", background:"#E3E3E3", display:"flex", padding:"4rem 1rem", flexDirection:"column", alignItems:"left"}}>
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <div>
                            <img src="/Images/gk-logo.png" alt="" height="150px"/>
                            <div style={{marginTop:"3px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <FacebookIcon style={{marginRight:"5px"}}/>
                                <TwitterIcon style={{marginRight:"5px"}}/>
                                <InstagramIcon style={{marginRight:"5px"}}/>
                            </div>
                        </div>
                    </div>
                    <div style={{ padding:"0 1rem", fontSize:"1rem", textAlign:"left"}}>
                            <h2 style={{color:"#7962D2"}}>Sitemap</h2>
                            <p style={{padding:"0.5rem 0", borderBottom:"1px solid gray",color:"gray", fontWeight:"bold", textAlign:"left", cursor:"pointer"}}>About</p>
                            <p style={{padding:"0.5rem 0", borderBottom:"1px solid gray", color:"gray", fontWeight:"bold", textAlign:"left", cursor:"pointer"}}>Home</p>
                            <p style={{padding:"0.5rem 0", borderBottom:"1px solid gray", color:"gray", fontWeight:"bold", textAlign:"left", cursor:"pointer"}}>Contact</p>
                            <p style={{padding:"0.5rem 0", color:"gray", fontWeight:"bold", textAlign:"left", cursor:"pointer"}}>Find Jobs</p>
                    </div>
                    <div style={{ minWidth:"30vw"}}>
                            <h2 style={{color:"#7962D2",padding:"0 1rem", textAlign:"left"}}>Site by </h2>
                            
                            <div style={{background:"#251552", margin:"0 5rem", borderRadius:"11px", padding:"1rem 3rem"}}>
                                <img src="/Images/pumori1.png" alt="my-logo" width="170px" />
                            </div>
                    </div>
                </div>
            </div>
        </Box>
        <div style={{color:"gray",padding:"1rem 0", borderTop:"1px solid gray", textAlign:"center", background:"#E3E3E3"}}>
			<div style={{fontSize: "11px"}}>© Copyright {getYear()}. GariKhau.com</div>
        </div>
    </>)
};
