import api from './api';

export class JobApplicationClient {
    static saveJobApplication=async (data:any)=>{
        return api.endpoint('JobApplication/PostAsync').postAsync(data);
    };
    static UpdateJobApplicationStatus = async (data:any)=>{
        return api.endpoint('JobApplication/UpdateJobApplicationStatusAsync').postAsync(data);
    };
    static getJobApplicationByUserId= async () =>{
        return api.endpoint('JobApplication/GetByUserIDAsync').getAsync();
    };
     static getJobApplicationByJobId= async (id:any) =>{
        return api.endpoint('JobApplication/GetByJobIdAsync/'+id).getAsync();
    };
    static getJobApplicationById= async (id:any) =>{
        return api.endpoint('JobApplication/GetByIdAsync/'+id).getAsync();
    };
    static downloadById= async (id:any) =>{
        return api.endpoint('Download/GetByUploadIdAsync/'+id).getAsync();
    };
}