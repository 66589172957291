import { JobFilterModel } from 'types/JobFilterModel';
import { RequestModel } from '../types/requests/RequestModel';
import { JobModel } from '../types/JobModel';
import { WebJson } from '../types/WebJson';
import { DashboardRequestModel } from '../types/DashboardRequestModel';
import api from './api';

export class JobClient {
    static getAsync = async (): Promise<WebJson<JobModel[]|any>> => {
        return api.endpoint('Job/GetAsync').getAsync();
    };

    static getFiltersAsync = async (): Promise<WebJson<JobFilterModel[]>> => {
        return api.endpoint('Job/GetFiltersAsync').getAsync();
    };

    static getSearchTextAsync = async (searchText:string): Promise<WebJson<JobModel[]>> => {
        return api.endpoint('Job/SearchJobsByText?searchText='+searchText).getAsync();
    };

    static getByIdAsync = async (id:number): Promise<WebJson<JobModel[]>> => {
        return api.endpoint('Job/GetByIdAsync/'+id).getAsync();
    };

    static createJob = async (data:RequestModel) => {
        return api.endpoint('Job/PostAsync').postAsync(data);
    };
     static updateJob = async (id:number, data:RequestModel) => {
        return api.endpoint('Job/UpdateAsync/'+id).putAsync(data);
    };

    static getJobsByFilter  = async (data:JobFilterModel[])=>{
        return api.endpoint('Job/SearchJobsByFilters').postAsync(data);
    };

    static getByUserAsync = async (): Promise<WebJson<JobModel[]>> => {
        return api.endpoint('Job/GetByUserIdAsync').getAsync();
    };

    static updateJobStatusAsync = async (data:RequestModel|any)=>{
        return api.endpoint('Job/UpdateJobStatusAsync').putAsync(data);
    };
    static getPaginatedJobs = async (data : RequestModel) => {
        return api.endpoint('Job/GetPaginatedJobsAsync').postAsync(data);
    };
    static getAllJObLocations = async () => {
        return api.endpoint('Job/GetAllJobLocation').getAsync();
    }

    static getDashboardInfo = async (data : DashboardRequestModel) => {
        return api.endpoint('Dashboard/GetDashboardDataAsync').postAsync(data);
    }
}