import React, { FunctionComponent } from 'react';
import Box from '@mui/material/Box';
import { SideBarMenu } from '../Dashboard/SideBarMenu';
import {Title} from "../../ui/Dashboard/Title";
import { CompanyModel } from '../../types/CompanyModel';
import { CompanyClient } from '../../apiClients/companyClient';
import Button from "@mui/material/Button";
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from '../../ui/PrimaryButton';
import { SecondaryButton } from '../../ui/SecondaryButton';
import { EmptyResultText } from '../../ui/EmptyResultText';


export const DashboardCompany: FunctionComponent = () => {
    const [companies, setCompanies] = React.useState<CompanyModel[]>([]);
    const history = useHistory();

    React.useEffect(() => {
        async function fetchCompanies() {
            const res = await CompanyClient.getAsync();
            if (res?.isSuccess && res?.data) {
                setCompanies(res.data);
            }
            if(res?.status === 401){
                history.push("/login");
            }   
        }
        fetchCompanies();
    }, []);


    const handleDeleteCompany = async (id : any) => {
        const response = await CompanyClient.softDeletAsync(Number(id));
        if(response?.isSuccess){
            const filteredData = companies?.filter((each:any)=>each.companyID != Number(id));
            setCompanies(filteredData);
            alert("Sucessfully Deleted");
        }else if(response?.status === 401){
            alert("Session expired, Login again");
            history.push("/login");
        }else{
            alert("Something went wrong deleting the company");
        }
    }

    return (
        <>
            <Box style={{margin:"0", padding:"0"}} sx={{ flexGrow: 1, display: { xs: 'none', md: 'inline' } }}>
                <div style={{minHeight:"100vh", display:"flex", justifyContent:"space-between"}}>
                    <SideBarMenu />
                    <div style={{minWidth:"70vw", marginTop:"2rem", marginBottom:"2rem", textAlign:"left", paddingLeft:"0rem", marginRight:"2rem" ,border:"1px solid #f1f1f1"}}>
                        <Title text="My Companies List"/>
                        <Button   
                            variant="contained" 
                            style={{backgroundColor:"#7962D2", color:"white", fontSize:"0.75rem", marginBottom:"1rem",marginLeft:"2rem"}} 
                            onClick={()=>{history.push("/addCompany")}}
                        >
                            Add a New Company
                        </Button>
                        <div>

                            {
                                (companies.length === 0) &&         
                                (
                                    <EmptyResultText text={"Sorry, No Companies available. You can start creating one."} />
                                )
                        
                            }
                            {companies?.map((each:any)=>{
                                return (
                                    <div key={each?.companyID} style={{borderBottom:"1px solid gray",background:"#f1f1f1", borderRadius:"10px", padding:"1rem 3rem", marginBottom:"10px"}}>
                                        <h3 style={{marginBottom:"0px"}}>{each?.name}</h3>
                                        <p style={{fontSize:"0.7rem", marginTop:"1px", color:"gray"}}>No. of Employees: {each?.numberOfEmployees} | {each?.webURL}</p>
                                        <div style={{marginTop:"1rem"}}>
                                          
                                            <PrimaryButton 
                                                buttonText='Details'
                                                onClick={()=>{history.push(`/companyDetails/${each?.companyID}`)}}
                                                margin={"0rem 0rem"}
                                            />
                                            <Button   
                                                variant="contained" 
                                                style={{backgroundColor:"gray", color:"white", fontSize:"0.9rem", marginRight:"1rem", marginLeft:"2rem"}} 
                                                onClick={()=>{history.push(`/editCompany/${each?.companyID}`)}}
                                            >
                                                Edit
                                            </Button>
                                            <SecondaryButton
                                                buttonText='Delete'   
                                                onClick={()=>{handleDeleteCompany(each?.companyID)}}
                                            />
                                        
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'inline', md: 'none' } }} >
                <SideBarMenu/>
                <div style={{padding:"0 1rem", marginBottom:"3rem", marginTop:"0rem", border:"1px solid #f1f1f1"}}>
                     <Title text="My Companies List"/>
                </div>
                <Button   
                    variant="contained" 
                    style={{backgroundColor:"#7962D2", color:"white", fontSize:"0.75rem", marginBottom:"1rem",marginLeft:"2rem"}} 
                    onClick={()=>{history.push("/addCompany")}}
                >
                    Add a New Company
                </Button>
                <div>
                    {companies?.map((each:any)=>{
                        return (
                            <div key={each?.companyID} style={{borderBottom:"1px solid gray",background:"#f1f1f1", borderRadius:"10px", padding:"1rem 3rem", marginBottom:"10px"}}>
                                <h3 style={{marginBottom:"0px"}}>{each?.name}</h3>
                                <p style={{fontSize:"0.7rem", marginTop:"1px", color:"gray"}}>No. of Employees: {each?.numberOfEmployees} | {each?.webURL}</p>
                                <div>
                                    <Button   
                                        variant="contained" 
                                        style={{backgroundColor:"#7962D2", color:"white", fontSize:"0.75rem", marginRight:"1rem"}} 
                                        onClick={()=>{history.push(`/companyDetails/${each?.companyID}`)}}
                                    >
                                        View Details
                                    </Button>
                                    <Button   
                                        variant="contained" 
                                        style={{backgroundColor:"gray", color:"white", fontSize:"0.75rem", marginRight:"1rem"}} 
                                        onClick={()=>{history.push(`/Edit/${each?.companyID}`)}}
                                    >
                                        Edit
                                    </Button>
                                    <Button   
                                        variant="contained" 
                                        style={{backgroundColor:"tomato", color:"white", fontSize:"0.75rem"}} 
                                        onClick={()=>{handleDeleteCompany(each?.companyID)}}
                                    >
                                        Delete 
                                    </Button>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Box>

        </>
    );
}
