import React from 'react';
import { useUserDispatchProvider } from '../UserProvider';
import { UserModel } from '../../types/UserModel';
import * as UserDispatcher from '../reducers/UserReducer';

interface useUserDispatcherType {
    setUserLogin: (user: UserModel) => void;
    setUserSignUp: (user: UserModel) => void;
    setUserLogout: () => void;
}

export const useUserDispatcher = (): useUserDispatcherType => {
    const { dispatch } = useUserDispatchProvider();

    const setUserLogin = React.useCallback((user:any) => {
        return dispatch(UserDispatcher.UserLogin(user));
    }, [dispatch]);

    const setUserSignUp = React.useCallback((user:any) => {
        return dispatch(UserDispatcher.UserSignUp(user));
    }, [dispatch]);

    const setUserLogout = React.useCallback(() => {
        return dispatch(UserDispatcher.UserLogout());
    }, [dispatch]);

    return {
        setUserLogin,
        setUserSignUp,
        setUserLogout
    }
};
