import React, { FunctionComponent } from 'react';
import { JobModel } from '../../types/JobModel';
import {JobFilterOptions} from "../../types/JobFilterModel";
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

interface Props{
    id?:number,
    title?:string,
    options:any,
    setFilters?:any,
    filters:JobFilterOptions | any,
    jobs?:JobModel[] | undefined,
    setDisplayJobs?:()=>void,
    jobFilterEnum?:number
}

export const RadioOptions:FunctionComponent<Props> = ({id,title, options,setFilters,filters,jobFilterEnum=101}) =>{
    return (
        <div 
            css={css({
                clear: 'both',
                margin: '.5em 0 1em 0',
                padding: '0 1.5em',
                flex: 1,
                color: 'rgba(0, 0, 0, .65)'
              })}
              id={String(id)}
        >
            <h4 id={title}>{title}</h4>
            <div css={{
                fontFamily: 'Lato, Helvetica Neue, Arial, Helvetica, sans-serif',
                width: '100%',
                margin: 0,
                outline: 0,
                lineHeight: '1.214em',
                padding: '.678em 1em',
                fontSize: '1em',
                background: '#fff',
                border: '1px solid rgba(34, 36, 38, .15)',
                borderRadius: '.28rem',
            }}
            >
                {options?.map((o:any) => {
                    return (
                        <div css={{margin:"5px 0px"}} key={o.value}>
                            <input 
                                type="checkbox" 
                                value={o.value}
                                defaultChecked ={filters[jobFilterEnum].includes(o.value)?true:false}
                                onChange={(e)=>{
                                    if(e.target.checked){
                                        // @ts-ignore
                                        setFilters({...filters,[id]:filters[id].concat(o.value)})
                                    }else{
                                        // @ts-ignore
                                        setFilters({...filters,[id]:filters[id].filter(x =>x != o.value)})
                                    }          
                                }} />{o.label}
                        </div>
                    );
                 })}

            </div>
        </div>
    )

};
