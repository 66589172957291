import { useParams } from "react-router";
import { Button, TextField }  from "@mui/material";
import { postAsync} from "../../apiClients/uploadClient";
import React, { useEffect, useState } from "react";
import { JobApplicationClient } from "../../apiClients/jobApplicationClient";
import { useHistory } from "react-router";
import { Textbox } from "../../ui/form/Textbox";
import { UserClient } from "../../apiClients/userClient";
import { getUploadbyId } from "../../apiClients/uploadClient";
import UploadTypeEnum from "../../enums/UploadTypeEnum";
import { localStorageDataUtil } from "../../utils/localStoarageUtil";


export const JobApplication = ()=>{
    const { jobId, title }:{jobId :string|undefined,title: string|undefined} = useParams();
    const [resumeId, setResumeId] = React.useState<any>();
    const [coverletterId,setCoverletterId] = React.useState<any>();
    const [applicationText] = React.useState("");
    const [resumeFile, setResumeFile] = React.useState<any>();
    const [coverletterFile, setCoverletterFile] = React.useState<any>();
    const [isResumeSelected, setIsResumeSelected] = useState(false);
    const [isCoverLetterSelected, setIsCoverLetterSelected] = useState(false);
    const [selectedResume, setSelectedResume] = useState<any>();
    const [selectedCoverletter, setSelectedCoverletter] = useState<any>();

    const history = useHistory();
    useEffect(()=>{
        const localData = localStorageDataUtil();
        if(!Boolean(localData.token)){
            history.push("/login");
        }
    });
   useEffect(()=>{
        async function fetchUserProfile() {
            const res:any = await UserClient.getUserProfileAsync();
            const data = res?.data;
            if(res?.isSuccess)
            {
                setResumeId(data?.resumeID);
                setCoverletterId(data?.coverletterID);
            }
            const cover = await getUploadbyId(data?.coverletterID);
            const resume = await getUploadbyId(data?.resumeID);
            if(resume?.isSuccess){
                setResumeFile(resume?.data);
            }
            if(cover?.isSuccess){
                setCoverletterFile(cover?.data);
            }
        }
        fetchUserProfile();
    },[])
    const user = localStorageDataUtil();
    console.log("####data- userdata", user);
    const uploadFile =async (file:any, uploadType:any)=>{
        const formData = new FormData();
        formData.append('File',file);
        formData.append('UploadType', uploadType);
        formData.append('CompanyID',"");
        const response = await postAsync(formData);
        if(uploadType === UploadTypeEnum.Resume && response?.isSuccess){
            setResumeId(response?.data);
            return response?.data;
        }
        if(uploadType === UploadTypeEnum.CoverLetter && response?.isSuccess){
            setCoverletterId(response?.data);
            return response?.data;
        }
        return false;
    };
     const submitApplication= async()=>{
        let res2 = coverletterId;
        let res1 = resumeId;
        if(isCoverLetterSelected){
            res2 = await uploadFile(selectedCoverletter, UploadTypeEnum.CoverLetter);
            setCoverletterId(res2);
        }
        if(isResumeSelected){
            res1 = await uploadFile(selectedResume, UploadTypeEnum.Resume);
            setResumeId(res1);
        }
        const result = await JobApplicationClient.saveJobApplication({
            coverLetterID : res2,
            resumeID: res1,
            jobID: jobId,
            applicationStatus: 501,
            applicationText:applicationText
        });
        if(result?.status === 401){
            history.push("/login");
        }
        if(result.isSuccess){
            alert("Sucessfully applied for this job!")
            history.push("/");
        }else{
            alert(result?.messages[0]?.message);
        }

    };

    return (
          <div style={{background:"#F6F6F6", marginTop:"0rem"}}>
            <div style={{minHeight:"90vh", display:"flex", justifyContent:"space-between", flexDirection:"row-reverse"}}>
                
                <div style={{minWidth:"65vw", marginTop:"2rem", marginBottom:"2rem", textAlign:"left", paddingLeft:"5rem"}}>
                    <p>Applying for the position</p>
                    <h2 style={{color:"#7962D2"}}>{title}</h2>
                    <div style={{minHeight:"60vh",  marginTop:"2rem", marginRight:"10%", display:"flex", flexDirection:"column", color:"gray"}}>
                        <div style={{display:"flex",  alignItems:"center", borderBottom:"1px solid #e3e3e3", padding:"1rem"}}>
                            <span style={{width:"9rem"}}><b>Name</b></span>
                            <TextField style={{margin:"5px 5px",width:"400px", background:"#E2E2E2", color:"black"}} size="small"  value={user?.firstName + " " + user?.lastName} id="fullWidth" variant="outlined" disabled/>
                        </div>
                        <div style={{display:"flex",  alignItems:"center", borderBottom:"1px solid #e3e3e3", padding:"1rem"}}>
                            <span style={{width:"9rem"}}><b>Email</b></span>
                            <TextField style={{margin:"5px 5px",width:"400px", background:"#E2E2E2", color:"black"}} size="small"  value={user?.email} id="fullWidth" variant="outlined" disabled/>
                        </div>

                        <span style={{marginLeft:"1rem"}}><b>Resume: </b><a href="#">{resumeFile?.filename}</a></span>
                            <Textbox id="resume" type="file"  onChange={(event:any)=>{
                                setSelectedResume(event.target.files[0]);
                                setIsResumeSelected(true);
                            }}/>
                            
                            <span style={{marginLeft:"1rem"}}><b>Coverleter: </b> <a href="#">{coverletterFile?.filename}</a></span>
                            <Textbox type="file" id="coverletrer" onChange={(event:any)=>{
                                setSelectedCoverletter(event.target.files[0]);
                                setIsCoverLetterSelected(true);
                            }}/>

                        <div style={{display:"flex",  justifyContent:"center", flexDirection:"column", width:"100%", padding:"1rem"}}>
                            <p style={{width:"9rem"}}><b>Message</b></p>
                            <TextField style={{margin:"5px 5px", background:"white", color:"black", width:"80%", padding:"5px 0"}} size="small"  label="" id="fullWidth" variant="outlined" multiline rows={"9"} />
                        </div>

                    </div>


                    <div style={{ marginTop:"2rem",marginRight:"25%", display:"flex", justifyContent:"center", alignItems:"center"}}>

                        <Button variant="contained" style={{backgroundColor:"#7962D2", color:"white", fontSize:"0.75rem", textAlign:"center"}} onClick={()=>submitApplication()}>Submit Application</Button>
                    </div>
                </div>
                <div style={{border:"1px solid white"}}>
                    <div style={{border:"1px solid #f3f3f3", height:"650px", width:"3o0px", margin:"2rem 5rem", borderRadius:"15px", padding:"0 0", overflow:"hidden", background:"#ECECEC"}}>
                            <div>
                                <img src={""} style={{background:"#d5d5d5", width:"200px", height:"100px", margin:"2rem 2rem 0 2rem", borderRadius:"11px"}} />
                            </div>
                            <p style={{color:"#7962D2", cursor:"pointer", fontSize:"13px", marginTop:"10px", textAlign:"center"}}>visit website</p>
                            
                            <div style={{height:"2.5rem"}}></div>
                            <div style={{borderBottom:"1px solid gray", textAlign:"left",margin:"0 2rem", marginTop:"4rem", paddingBottom:"1rem", marginBottom:"1rem"}}>
                                <h4 style={{margin:"2px 0", color:"gray"}}>Job-Type</h4>
                                <h4 style={{margin:"2px 0"}}>Full-Time</h4>
                            </div>
                            <div style={{borderBottom:"1px solid gray", textAlign:"left",margin:"0 2rem", paddingBottom:"1rem", marginBottom:"1rem"}}>
                                <h4 style={{margin:"2px 0", color:"gray"}}>Location</h4>
                                <h4 style={{margin:"2px 0"}}>Kathmandu, Nepal</h4>
                            </div>
                            <div style={{ textAlign:"left",margin:"0 2rem", paddingBottom:"1rem", marginBottom:"1rem"}}>
                                <h4 style={{margin:"2px 0", color:"gray"}}>Apply Berfore</h4>
                                <h4 style={{margin:"2px 0"}}>11 Sept, 2022</h4>
                            </div>
                            
                    </div>
                </div>
            </div>

        </div>
    );
};