import { useHistory, useParams } from "react-router-dom";
import React, { FunctionComponent } from 'react';
import Button from "@mui/material/Button";
import { UserClient } from "../../apiClients/userClient";
import { VerifyEmailRequest } from "../../types/requests/VerifyEmailRequest";

export const VerifyEmail: FunctionComponent = () => {
    const history = useHistory();
    const {confirmationToken}:{confirmationToken: string} = useParams();
    const verifyEmailAsync =async ()=>{
        const data:VerifyEmailRequest = {
            userID:0,
            token:confirmationToken
        }
        const response =await  UserClient.verifyEmailAsync(data);
        if(response.isSuccess){
            const data : string =  window.localStorage.getItem("gk_data") || "";
            if(data.length > 0){
                const decodedData = window.atob(data);
                const finalData = JSON.parse(decodedData);
                finalData.verified = true;
                const stringData = JSON.stringify(finalData);
                const encodedData = window.btoa(stringData);
                window.localStorage.setItem('gk_data', encodedData);
                alert("Sucessfully confirmed your email");
                history.push("/")
            }
            else{
                alert("Something went wrong.")
            }
        }else{
            alert("Cannot confirm your email")
        }
    }
    return(
    <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",width:"100%", minHeight:"35vh"}}>
        <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",width:"100%"}}>
            <h1 style={{color:"black"}}>Verify your email by clicking the button below</h1>
            <Button variant="contained" style={{backgroundColor:"#7962D2", color:"white"}} onClick={()=>{verifyEmailAsync()}}>Verify Email</Button>
        </div>       
    </div>
)};

