export enum SSOLoginTypeEnum {
    Google = 1001,
    Facebook = 1002,
    Twitter = 1003,
    LinkedIn = 1004,
    Microsoft = 1005,
    Unknown =0,
}

export default SSOLoginTypeEnum;
