import React, { FunctionComponent, useEffect } from 'react';
import { Dropdown, DropdownOptions } from '../../ui/form/Dropdown';
import JobFilterEnum from '../../enums/JobFilterEnum';
import { RadioOptions } from '../../ui/form/RadioOptions';
import { useQuery } from "react-query";
import { JobClient } from "../../apiClients/jobClient";
import { JobFilterModel } from 'types/JobFilterModel';
import {useRecoilState} from "recoil";
import { JobFilterOptionsAtom } from '../../state/JobFilter';
import { JobModel } from '../../types/JobModel';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { JobFilterOptions } from '../../types/JobFilterModel';

interface props { 
  jobs?: JobModel[];
  setDisplayJobs: (jobs: JobModel[]|undefined)=> void;
  isHome ?: boolean;
  jobFilterIds: any,
  setJobFilterIds: any,
  searchText: any
}
const defaultFilterValue:JobFilterOptions = {
    [JobFilterEnum.EmploymentType]:[],
    [JobFilterEnum.Location]:[],
    [JobFilterEnum.Category]:[],
    [JobFilterEnum.CompensationType]:[],
    [JobFilterEnum.ExperienceLevel]:[],
    [JobFilterEnum.Skills]:[],
    [JobFilterEnum.EducationLevel]:[],
    [JobFilterEnum.CompensationCurrency]:[],
    [JobFilterEnum.LocationCountry]:[]
}

export const JobFilters: FunctionComponent<props> = ({jobs,setDisplayJobs, jobFilterIds, setJobFilterIds, searchText}) => {
  const [, setJobFilters] = useRecoilState(JobFilterOptionsAtom);
  const {isLoading, isSuccess, data} = useQuery('JobFilters',()=>JobClient.getFiltersAsync());
  
  const [filters, setFilters] = React.useState<any>(defaultFilterValue);
  const history = useHistory();

  const [allFilters, setAllFilters] = React.useState<any>();
    useEffect(()=>{
        if(Boolean(allFilters))
          {  
            let filterValues:JobFilterOptions = {
                [JobFilterEnum.EmploymentType]:[],
                [JobFilterEnum.Location]:[],
                [JobFilterEnum.Category]:[],
                [JobFilterEnum.CompensationType]:[],
                [JobFilterEnum.ExperienceLevel]:[],
                [JobFilterEnum.Skills]:[],
                [JobFilterEnum.EducationLevel]:[],
                [JobFilterEnum.CompensationCurrency]:[],
                [JobFilterEnum.LocationCountry]:[]
            }
            jobFilterIds?.map((each:any)=>{
                if(each >= allFilters["101"][0] && each<= allFilters["101"][allFilters["101"].length -1])
                {
                    // @ts-ignore
                    filterValues[JobFilterEnum.EmploymentType].push(each);
                }
                else if(each >= allFilters["102"][0] && each<= allFilters["102"][allFilters["102"].length -1])
                {
                    // @ts-ignore
                    filterValues[JobFilterEnum.Location].push(each);
                }
                else if(each >= allFilters["103"][0] && each<= allFilters["103"][allFilters["103"].length -1])
                {
                    // @ts-ignore
                    filterValues[JobFilterEnum.Category].push(each);
                }
                else if(each >= allFilters["104"][0] && each<= allFilters["104"][allFilters["104"].length -1])
                {
                    // @ts-ignore
                    filterValues[JobFilterEnum.CompensationType].push(each);
                }
                else if(each >= allFilters["105"][0] && each<= allFilters["105"][allFilters["105"].length -1])
                {
                    // @ts-ignore
                    filterValues[JobFilterEnum.ExperienceLevel].push(each);
                }
                else if(each >= allFilters["106"][0] && each<= allFilters["106"][allFilters["106"].length -1])
                {
                    // @ts-ignore
                    filterValues[JobFilterEnum.Skills].push(each);
                }
                else if(each >= allFilters["107"][0] && each<= allFilters["107"][allFilters["107"].length -1])
                {
                    // @ts-ignore
                    filterValues[JobFilterEnum.EducationLevel].push(each);
                }
                else if(each >= allFilters["108"][0] && each<= allFilters["108"][allFilters["108"].length -1])
                {
                    // @ts-ignore
                    filterValues[JobFilterEnum.CompensationCurrency].push(each);
                }
                else {
                     // @ts-ignore
                    filterValues[JobFilterEnum.LocationCountry].push(each);
                }
           
            setFilters(filterValues);
            });
        }
    },[jobFilterIds, allFilters]);

    useEffect(()=>{
        async function fetchAllFilters(){
            const result = await JobClient.getFiltersAsync();
            let res = {

            };
            result?.data?.map((each:any)=>{
                let values:any = [];
                each?.options?.map((e:any)=>{
                    values.push(e.value);
                })
                // @ts-ignore
                res[each.type] = values;
            });
            setAllFilters(res);            
        }
        fetchAllFilters();
       
    },[]);
 
  useEffect(()=>{
    if(isSuccess && !isLoading){
      setJobFilters(data?.data as JobFilterModel[])
      setJobFilterIds([]);
    }
  },[isSuccess,isLoading,data]);


  const handleChange=(options:DropdownOptions[]|DropdownOptions, filterType:JobFilterEnum)=>{
    let valuesList = (options as DropdownOptions[])?.map(x=> x.value);
    // const res = {...filters, [filterType]:valuesList};
    if(options == null){valuesList =[]}
    setFilters({...filters, [filterType]:valuesList})
  }

  const handleSubmit = () => {
    let allFilters:any = [];
    for(const key in filters){
        filters[key]?.map((e:any)=>{
            allFilters.push(e);
        })
    };
    const c = allFilters.concat(jobFilterIds);
    let d = c.filter((value:any, index:any) => c.indexOf(value) === index);
    setJobFilterIds(d);
    const filterText:String = d.join(",");
    // @ts-ignore
    history.push({pathname:"/search/results", search:"?"+ new URLSearchParams({title: searchText, filters: filterText, pageNum:"1"}).toString()});
    }
  return(

    // <div style={{minHeight:"90vh", display:"flex"}}>
        <div style={{border:"1px solid white", background:"#f6f6f6", padding:"10px 20px"}}>
            <div style={{border:"1px solid #f3f3f3", width:"400px", margin:"2rem 5rem", borderRadius:"15px", padding:"0 0", overflow:"hidden", background:"#ECECEC"}}>
                {/* <div> */}
                    <h3 style={{color:"white", background:"#7962D2", padding:"15px 0", margin:"0 0", borderRadius:"15", textAlign:"center"}}>Filters</h3>
                {/* </div> */}
                <div style={{background:"#ECECEC"}}>
        
        <RadioOptions 
          id = {JobFilterEnum.EmploymentType}
          title="Employment Type" 
          options={data?.data?.find(x=>(x.type)==JobFilterEnum.EmploymentType)?.options}
          setFilters = {setFilters}
          filters = {filters}
          jobFilterEnum = {JobFilterEnum.EmploymentType}
          jobs = {jobs} 
          setDisplayJobs = {()=>setDisplayJobs}
        />
         <Dropdown 
          multiSelectable={true} 
          label="Category/Industry" 
          id="category" 
          options={data?.data?.find(x=>(x.type)==JobFilterEnum.Category)?.options as DropdownOptions[]}
          onChange={options => handleChange(options,JobFilterEnum.Category)}
          value={filters[JobFilterEnum.Category]}
        />
        <RadioOptions
          id = {JobFilterEnum.LocationCountry}
          title="Country"
          options={data?.data?.find(x=>(x.type)==JobFilterEnum.LocationCountry)?.options}
          setFilters = {setFilters}
          filters = {filters}
          jobFilterEnum = {JobFilterEnum.LocationCountry}
        />
        <Dropdown 
          multiSelectable={true} 
          label="Skills" 
          id="Skills" 
          options={data?.data?.find(x=>(x.type)==JobFilterEnum.Skills)?.options as DropdownOptions[]}
          onChange={options => handleChange(options,JobFilterEnum.Skills)} 
          value={filters[JobFilterEnum.Skills]}
        />
        <RadioOptions
          id = {JobFilterEnum.EducationLevel}
          title="Education Level"
          options={data?.data?.find(x=>(x.type)==JobFilterEnum.EducationLevel)?.options}
          setFilters = {setFilters}
          filters = {filters}
          jobFilterEnum = {JobFilterEnum.EducationLevel}
        />
        <RadioOptions
          id = {JobFilterEnum.CompensationCurrency}
          title="Compensation Currency"
          options={data?.data?.find(x=>(x.type)==JobFilterEnum.CompensationCurrency)?.options}
          setFilters = {setFilters}
          filters = {filters}
          jobFilterEnum = {JobFilterEnum.CompensationCurrency}
        />
        <Dropdown 
          multiSelectable={true} 
          label="Compensation Type" 
          id="compensation type" 
          options={data?.data?.find(x=>(x.type)==JobFilterEnum.CompensationType)?.options as DropdownOptions[]}
          onChange={options => handleChange(options,JobFilterEnum.CompensationType)} 
          value={filters[JobFilterEnum.CompensationType]}
        />
        <div style={{display:"flex", justifyContent:"center", alignItems:"center", margin:"2rem 0"}}>
            <Button variant="contained" style={{backgroundColor:"#7962D2", color:"white"}} onClick={()=>{handleSubmit()}}>Filter</Button>
        </div>
            </div>
         </div>
        </div>
    // </div>
   
  );
}
