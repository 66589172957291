import React, { Dispatch, FunctionComponent, useReducer } from 'react';
import { makeUserReducer, initUserState, UserState, UserAction } from './reducers/UserReducer';
import { createContextWithoutDefault } from '../utils/createContextWithoutDefault';

function useUserReducer(): [UserState, Dispatch<UserAction>] {
	const userReducer = makeUserReducer();
	const emptyUserObject = initUserState();

	return useReducer(userReducer, emptyUserObject);
}

export type UserDispatchContextProps = {
	dispatch: React.Dispatch<UserAction>;
}

const [UserStateProvider, useUserStateProvider] = createContextWithoutDefault<UserState>();
const [UserDispatchProvider, useUserDispatchProvider] = createContextWithoutDefault<UserDispatchContextProps>();

type UserProviderProps = {
	children: React.ReactNode;
};

const UserProvider: FunctionComponent<UserProviderProps> = ({ children }: UserProviderProps) => {
	const [state, dispatch] = useUserReducer();

	const providerProps: UserDispatchContextProps = React.useMemo(() => {
		return {
			dispatch
		};
	}, [dispatch]);

	return (
        // @ts-ignore
		<UserStateProvider value={state}>
            {/* @ts-ignore */}
			<UserDispatchProvider value={providerProps}>
				{children}
			</UserDispatchProvider>
		</UserStateProvider>
	);
};

export {
	UserProvider,
	useUserStateProvider,
	useUserDispatchProvider
};
