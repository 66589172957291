import React, { FunctionComponent, SyntheticEvent } from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

interface Props {
    title?: string;
    handleSubmit: (e: SyntheticEvent) => Promise<void>;
    formResult?: JSX.Element | undefined;
    formWidth?: number;
    children: React.ReactElement[];
}

export const FormContainer: FunctionComponent<Props> = ({
    title, handleSubmit, formResult, formWidth, children
}) => {
    return (
        <form css={css({ margin: '0 auto', padding: '25px 40px', width: "100%" })} onSubmit={handleSubmit}>
            <div css={css({
                textAlign: 'center',
                alignSelf: 'center',
                backgroundColor: '#FFF',
                boxShadow: 'rgba(0, 0, 0, 0.12) 0px 12px 40px',
                borderRadius: '5px',
                padding: '30px',
            })}
            >
                <h1 css={css({ fontWeight: 400, fontSize: '34px', width:formWidth })}>
                    {title}
                </h1>

                {formResult}

                {children}
            </div>
        </form>
    );
};
