import React,{FunctionComponent} from 'react';
import { useHistory } from 'react-router-dom';
import { JobModel } from '../../types/JobModel';
import { Box }  from "@mui/material";
import Avatar from '@mui/material/Avatar';



interface props {
    displayJobs?: JobModel[] | any;
}
export const JobCards: FunctionComponent<props> =({displayJobs})=> {
    const history =useHistory();
    const getDate = (data: any) => {
        const myDate = new Date(data);
        const result = myDate.getFullYear().toString() +"-"+ myDate.getMonth().toString() +"-"+myDate.getDate().toString();
        return result;
    }
    return(
        displayJobs?.map((each:any)=>{
            return(
                <>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'inline' } }} >
                        <div style={{background:"#F8F8F8", borderRadius:"15px", display:"flex", width:"100%", margin:"0 2rem", padding:"1.2rem 1rem",  alignItems:"center", cursor:"pointer", borderBottom:"1px solid gray"}} >
                            {(Boolean(each?.company?.companyLogoURL)) ?
                            (<div >
                                <img style={{height:"100px", width:"100px", borderRadius:"50%", background:"#D9D9D9"}} src={each?.company?.companyLogoURL} />
                            </div>) :
                             <Avatar sx={{ bgcolor: "blue", height:"70px", width:"70px" }}>{each?.company?.name[0]}</Avatar>
                            }
                            <div style={{ minWidth:"500px", textAlign:"left", padding:"2px 4rem"}}>
                                <h2 style={{margin:"0 0", marginTop:"5px", color:"#7962D2"}} onClick={()=>{
                                    history.push({pathname:`/jobdetails/${each.jobID}`,state:{"isApplicable":true}});
                                }} >{each.title}</h2>

                                <p style={{color:"gray", margin:"5px 0", fontSize:"13px", fontWeight:"bold"}}>{each?.company?.name} | Hires: {each?.positionsAvailable} | Rs {each?.compensation}</p>
                            </div>
                            <div style={{}}>
                                <p style={{color:"gray", margin:"0 0", fontSize:"13px", marginTop:"40px"}}>apply before {getDate(each?.applicationEndDate)}</p>
                            </div>
                        </div>
                    </Box>
                    <Box style={{margin:"0", padding:"0"}} sx={{ flexGrow: 1, display: { xs: 'inline', md: 'none' } }}>
                        <div style={{background:"#F8F8F8", borderRadius:"15px", display:"flex", width:"90%", margin:"0 1.5rem", padding:"1rem 0.8rem",  alignItems:"center", cursor:"pointer", borderBottom:"1px solid gray"}} >
                            {(Boolean(each?.company?.companyLogoURL)) ?
                            (<div >
                                <img style={{height:"100px", width:"100px", borderRadius:"50%", background:"#D9D9D9"}} src={each?.company?.companyLogoURL} />
                            </div>) :
                             <Avatar sx={{ bgcolor: "#7962D2", height:"70px", width:"70px" }}>{each?.company?.name[0]}</Avatar>
                            }                           <div style={{ minWidth:"500px", textAlign:"left", padding:"2px 4rem"}}>
                                <h3 style={{margin:"0 0", marginTop:"5px", color:"#7962D2", width:"70%"}} onClick={()=>{
                                    history.push({pathname:`/jobdetails/${each.jobID}`,state:{"isApplicable":true}});
                                }} >{each.title}</h3>

                                <p style={{color:"gray", margin:"5px 0", fontSize:"10px"}}>{each?.company?.name} | Hires: {each?.positionsAvailable} | Rs {each?.compensation}</p>
                            </div>
                            <div style={{}}>
                                <p style={{color:"gray", margin:"0 0", fontSize:"10px", marginTop:"40px"}}>apply before {getDate(each?.applicationEndDate)}</p>
                            </div>
                        </div>
                    </Box>
                </>
            )
        })
       
    )
}
