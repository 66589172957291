import React, { FunctionComponent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserDispatcher } from '../../state/hooks/useUserDispatcher';

export const Logout: FunctionComponent<{}> = () => {
    const { setUserLogout } = useUserDispatcher();
    const history = useHistory();
    useEffect(() => {
        window.localStorage.removeItem('gk_data');
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('user');
        window.localStorage.removeItem('verified');
        setUserLogout();
        history.push("/");
    }, []);

    return (
        <div>
            Logout
        </div>
    );
};
