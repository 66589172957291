import { RequestModel } from '../types/requests/RequestModel';
import 'whatwg-fetch';
import * as ApiHelper from './apiHelper';
import { QueryParams } from './apiHelper';
import { localStorageDataUtil } from '../utils/localStoarageUtil';

export default {
    endpoint(endpoint: string) {
        const localData = localStorageDataUtil();
        const token = localData?.token;
        function url(params?: QueryParams[]) {
            return ApiHelper.apiUrl(endpoint, params);
        }


        return {
            getAsync: async (params?: QueryParams[]) => {
                try {
                    const serviceUrl = url(params);
                    const response = await fetch(serviceUrl, {
                        credentials: 'same-origin',
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    if(response.status === 401){ 
                        window.localStorage.removeItem("gk_data");
                        return response;
                    }
                    if (response.ok) return response.json();

                    throw new Error(response?.toString());
                } catch (error) {
                    return error;
                }
            },
            postAsync: (jsonObj: RequestModel) => fetch(url(), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(jsonObj),
                credentials: 'same-origin',
            }).then((res) => {
                   if(res.status === 401){
                        window.localStorage.removeItem("gk_data");
                        return res;
                    };
                    return res.json()
                }
                )
                .then((data) => data)
                .catch((error) => error),
            putAsync: (jsonObj: RequestModel) => fetch(url(), {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(jsonObj),
                credentials: 'same-origin',
            }).then((res) => res.json())
                .then((data) => data)
                .catch((error) => error),

            uploadAsync: async (obj: FormData) => {
                try {
                    const response = await fetch(url(), {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                        body: obj,
                        credentials: 'same-origin',
                    });

                    if (response.ok) return response.json();

                    throw new Error(response?.toString());
                } catch (error) {
                    return error;
                }
            },

        };
    },
};
